export enum NavlinkPath {
  root = "/",

  //home
  home = "/home",

  //dashboard
  dashboard = `${home}/dashboard`,

  // qrcode
  qrCode = `${home}/qr-code`,
  creatQrCode = `${home}/create-qr`,
  detailQrCode = `${qrCode}/detail-qr`,

  // stats
  stats = `${home}/stats`,

  // profile
  profile = `${home}/profile`,

  // billing
  billing = `${home}/billing`,

  // orders
  orders = `${home}/orders`,

  // checkout
  checkout = `/checkout`,

  // payment
  payment = `${home}/payment`,
  paymentSuccess = `${payment}/success/:id`,

  // user
  userAll = `${home}/user-all-users`,
  userPayingUser = `${home}/user-paying-users`,
  userNonePayingUser = `${home}/user-non-paying-users`,

  // finance
  financePlants = `${home}/finance-plants`,
  financeSubscriptions = `${home}/finance-subscriptions`,
  financeTransactions = `${home}/finance-transactions`,
  financeProcessors = `${home}/finance-processors`,
  financeCurrencies = `${home}/finance-currencies`,

  // contents
  contentBlogs = `${home}/content-blogs`,
  contentBlocks = `${home}/content-blocks`,
  contentCustomCode = `${home}/content-custom-code`,
  contentPages = `${home}/content-pages`,

  // contacts
  contactsForm = `${home}/contact-form`,

  // system
  systemStatus = `${home}/system-status`,
  systemSetting = `${home}/system-settings`,
  systemNotification = `${home}/system-notifications`,
  systemSmsPortal = `${home}/system-sms-portals`,
  systemDomain = `${home}/system-domains`,

  //sell
  sell = "/sell",

  // faq
  faq = `${sell}/faq`,

  //pricing
  pricing = `${sell}/pricing`,

  //docs
  docs = `${sell}/docs`,

  //auth
  auth = "/auth",
  login = `${auth}/login`,
  register = `${auth}/register`,
  resetPassword = `${auth}/reset-pasword`,
  resetPasswordValidEmail = `${auth}/reset-pasword-valid-email`,
  socialAuthRedirect = `${auth}/oauth2/redirect`,

  //public
  public = "/public",
  publicQr = `/:id`,
}
