import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const FutureComponent: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        gcTime: 10 * 1000 * 60,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
