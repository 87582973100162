import { useEffect, ReactPortal } from "react";
import { NavlinkPath } from "./services/router-config";
import { useAppBloc } from "./contexts/AppContext";
import { useBaseBloc } from "./services/base-bloc";
import { createPortal } from "react-dom";
import {
  drawerId,
  editorLayoutSideBarMobile,
  loadingId,
  messageId,
  modalId,
} from "./services/constants";
import { OverlayComponent } from "./components/overlay/OverlayComponent";
import QrLoader from "./components/loader/QrLoader";
import { queueScheduler } from "rxjs";
import { useQuery } from "@tanstack/react-query";
import { LoginStatus } from "./models/LoginStatus";
import { qrfyConfig } from "./apis/qrfy/QrfyRepositories";
import "@/styles";
import dayjs from "dayjs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

interface AppState {
  isLogin: boolean;
  isShowMessage: boolean;
  isShowDrawer: boolean;
  isShowLoading: boolean;
  isShowSidebar: boolean;
  models: ReactPortal[];
}

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { appBloc } = useAppBloc();
  const [state, setState] = useBaseBloc<AppState>({
    isLogin: false,
    isShowMessage: false,
    isShowDrawer: false,
    isShowLoading: false,
    isShowSidebar: false,
    models: [],
  });

  const { isLoading, isError } = useQuery({
    queryKey: ["get-access-token-validation"],
    queryFn: async () => {
      if (
        !location.pathname.startsWith(NavlinkPath.home) &&
        !location.pathname.startsWith(NavlinkPath.auth)
      ) {
        return 0;
      }

      try {
        if (appBloc.storage.refreshToken.length > 0) {
          const ret = await appBloc.repository.qrfyRepo.auth.refreshToken({
            refreshToken: appBloc.storage.refreshToken,
          });
          appBloc.storage.accessToken = ret.token;
          appBloc.storage.refreshToken = ret.refreshToken;
          appBloc.session.loginStatus.next(LoginStatus.Success);
          return LoginStatus.Success;
        }

        appBloc.session.loginStatus.next(LoginStatus.Expired);
        return LoginStatus.Expired;
      } catch (error) {
        console.error(error);
        appBloc.session.loginStatus.next(LoginStatus.Expired);
        return LoginStatus.Expired;
      }
    },
  });

  useEffect(() => {
    if (!isLoading) {
      let countQueue = 0;
      let currentLoginStatus = LoginStatus.Idle;

      // stream login status
      appBloc.session.loginStatus.subscribe(async (v) => {
        if (currentLoginStatus === v) {
          // return;
        } else {
          currentLoginStatus = v;
        }

        if (v === LoginStatus.Success) {
          qrfyConfig.accessToken = appBloc.storage.accessToken;
          appBloc.storage.isLogin = true;
          console.log(location.pathname);

          if (
            location.pathname.startsWith(NavlinkPath.auth) ||
            location.pathname === NavlinkPath.home ||
            location.pathname === NavlinkPath.home.replace("/", "") ||
            location.pathname.replace("/", "") === ""
          ) {
            navigate(`${NavlinkPath.creatQrCode}${window.location.search}`, {
              replace: true,
            });
          } else {
            navigate(`${location.pathname}${window.location.search}`, {
              replace: true,
            });
          }

          const userInfo = await appBloc.repository.qrfyRepo.user.getUserInfo();
          appBloc.session.isExpiredSubcription = userInfo.subscription
            .expireTime
            ? dayjs(userInfo.subscription.expireTime).millisecond() >=
              Date.now()
            : true;
        } else if (v === LoginStatus.Expired) {
          appBloc.storage.clearAll();
          qrfyConfig.accessToken = undefined;

          if (appBloc.storage.refreshToken !== "") {
            appBloc.repository.qrfyRepo.auth.logout({
              refreshToken: appBloc.storage.refreshToken,
            });
          }

          navigate(`${NavlinkPath.login}${window.location.search}`, {
            replace: true,
          });
        }
      });

      // stream error handler
      appBloc.session.error.subscribe(() => {});

      // listen for loading page
      appBloc.session.isLoading.subscribe((v) => {
        if (state.isShowLoading !== v) {
          state.isShowLoading = v;
          setState();
        }
        if (v) {
          document.body.className = "block-scroll";
        } else {
          document.body.className = "";
        }
      });

      // listen for show sidebar mobile
      appBloc.session.isShowSideBar.subscribe((v) => {
        if (state.isShowSidebar !== v) {
          state.isShowSidebar = v;
          setState();
        }
        if (v) {
          document.body.className = "block-scroll";
        } else {
          document.body.className = "";
        }
      });

      // listen for show drawer
      appBloc.session.isShowDrawer.subscribe((v) => {
        if (state.isShowDrawer !== v) {
          state.isShowDrawer = v;
          setState();
        }
        if (v) {
          document.body.className = "block-scroll";
        } else {
          document.body.className = "";
        }
      });

      // listen for show message modal
      appBloc.session.isShowMessage.subscribe((v) => {
        if (state.isShowMessage !== v) {
          state.isShowMessage = v;
          setState();
        }
      });

      // listen fo queue modal
      appBloc.session.queueModal.subscribe((v) => {
        if (v === null) {
          if (countQueue > 0) {
            countQueue--;
            const currentModel = document.getElementById(
              `${modalId}-${countQueue}`,
            );
            currentModel!.firstElementChild!.className =
              "column animation-faded--out";
            setTimeout(() => {
              state.models = state.models.slice(0, countQueue);
              setState();
            }, 150);
          }
        } else {
          const queueMo = state.models.slice(0, countQueue);
          const idx = countQueue;
          queueMo.push(
            createPortal(
              <OverlayComponent
                id={`${modalId}-${idx}`}
                classChildren={"column animation-scale--up animation-faded--in"}
                classBackground="overlay-queue-modal"
                onTapBackGround={() => {
                  appBloc.session.queueModal.next(null);
                }}
                onTapChildren={(e) => {
                  e.stopPropagation();
                }}
              >
                {v}
              </OverlayComponent>,
              document.body,
              `${modalId}-${idx}`,
            ),
          );
          countQueue++;
          state.models = queueMo;
          queueScheduler.schedule(() => {
            setState();
          });
        }

        if (countQueue === 1) {
          document.body.className = "block-scroll";
        } else if (countQueue === 0) {
          document.body.className = "";
        }
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      document.getElementById("qrf-app-root-animation-loading")?.remove();
    }
  }, [isLoading]);

  if (isLoading || isError) {
    return <></>;
  }

  return (
    <>
      <Outlet />
      {/* handler UI message */}
      {state.isShowMessage &&
        createPortal(appBloc.session.message.value, document.body, messageId)}

      {/* handler UI drawer */}
      {state.isShowDrawer &&
        createPortal(
          <OverlayComponent
            classChildren={undefined}
            classBackground="overlay-drawer animation-faded--in"
            onTapBackGround={() => {
              const drawer = document.getElementById(drawerId);
              if (drawer) {
                drawer!.firstElementChild!.className =
                  "overlay-drawer animation-faded--out";
              }
              setTimeout(() => {
                appBloc.session.isShowDrawer.next(false);
                appBloc.session.drawer.next(null);
              }, 150);
            }}
            onTapChildren={(e) => {
              e.stopPropagation();
            }}
            id={drawerId}
          >
            {appBloc.session.drawer.value}
          </OverlayComponent>,
          document.body,
          drawerId,
        )}

      {/* handler UI loading */}
      {state.isShowLoading &&
        createPortal(
          <OverlayComponent
            classChildren={undefined}
            classBackground="overlay-loading"
            onTapBackGround={() => {}}
            onTapChildren={() => {}}
            id={loadingId}
          >
            <div
              className="column"
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
              }}
            >
              <QrLoader height={150} width={150} />
            </div>
          </OverlayComponent>,
          document.body,
          loadingId,
        )}

      {/* handler UI sidebar mobile */}
      {state.isShowSidebar &&
        createPortal(
          <OverlayComponent
            classChildren={undefined}
            classBackground="overlay-drawer"
            onTapBackGround={() => {
              appBloc.session.isShowSideBar.next(false);
              const sidebar = document.getElementById(
                editorLayoutSideBarMobile,
              );
              if (sidebar) {
                sidebar.style.display = "flex";
                requestAnimationFrame(() => {
                  sidebar.classList.remove("animation-faded--in");
                  sidebar.classList.add("animation-faded--out");
                  sidebar.style.translate = "0%";
                });
              }
            }}
            onTapChildren={() => {}}
            id={loadingId}
          >
            <></>
          </OverlayComponent>,
          document.body,
          editorLayoutSideBarMobile,
        )}

      {/* queue potal */}
      {state.models.length > 0 &&
        state.models.map((d) => {
          return d;
        })}
    </>
  );
}

export default App;
