/* tslint:disable */
/* eslint-disable */
/**
 * Smart-Traffic-Service
 * Smart-Traffic-Service API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface AccessTokenResponse
 */
export interface AccessTokenResponse {
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof AccessTokenResponse
   */
  otherClaims?: { [key: string]: object };
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  token?: string;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponse
   */
  expiresIn?: number;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponse
   */
  refreshExpiresIn?: number;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  refreshToken?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  tokenType?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  idToken?: string;
  /**
   *
   * @type {number}
   * @memberof AccessTokenResponse
   */
  notBeforePolicy?: number;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  sessionState?: string;
  /**
   *
   * @type {string}
   * @memberof AccessTokenResponse
   */
  scope?: string;
}
/**
 *
 * @export
 * @interface AdminLoginRequest
 */
export interface AdminLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AdminLoginRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof AdminLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface BillDataDto
 */
export interface BillDataDto {
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  taxId?: string;
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof BillDataDto
   */
  currency?: BillDataDtoCurrencyEnum;
}

export const BillDataDtoCurrencyEnum = {
  Vnd: "VND",
  Usd: "USD",
} as const;

export type BillDataDtoCurrencyEnum =
  (typeof BillDataDtoCurrencyEnum)[keyof typeof BillDataDtoCurrencyEnum];

/**
 *
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  currentPassword: string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface ColorStyle
 */
export interface ColorStyle {
  /**
   *
   * @type {string}
   * @memberof ColorStyle
   */
  fromColor?: string;
  /**
   *
   * @type {string}
   * @memberof ColorStyle
   */
  toColor?: string;
  /**
   *
   * @type {number}
   * @memberof ColorStyle
   */
  rotation?: number;
  /**
   *
   * @type {string}
   * @memberof ColorStyle
   */
  type?: string;
}
/**
 *
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
  /**
   *
   * @type {string}
   * @memberof ContactInfo
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfo
   */
  website?: string;
}
/**
 *
 * @export
 * @interface ContentStyle
 */
export interface ContentStyle {
  /**
   *
   * @type {string}
   * @memberof ContentStyle
   */
  primaryColor?: string;
  /**
   *
   * @type {string}
   * @memberof ContentStyle
   */
  secondaryColor?: string;
  /**
   *
   * @type {string}
   * @memberof ContentStyle
   */
  titleFont?: string;
  /**
   *
   * @type {string}
   * @memberof ContentStyle
   */
  contentFont?: string;
}
/**
 *
 * @export
 * @interface CornerStyle
 */
export interface CornerStyle {
  /**
   *
   * @type {string}
   * @memberof CornerStyle
   */
  dotColor?: string;
  /**
   *
   * @type {string}
   * @memberof CornerStyle
   */
  dotStyle?: string;
  /**
   *
   * @type {string}
   * @memberof CornerStyle
   */
  squareColor?: string;
  /**
   *
   * @type {string}
   * @memberof CornerStyle
   */
  squareStyle?: string;
}
/**
 *
 * @export
 * @interface CreateFolderRequest
 */
export interface CreateFolderRequest {
  /**
   *
   * @type {string}
   * @memberof CreateFolderRequest
   */
  name?: string;
}
/**
 *
 * @export
 * @interface CreateLandingPageRequest
 */
export interface CreateLandingPageRequest {
  /**
   *
   * @type {string}
   * @memberof CreateLandingPageRequest
   */
  qrName: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof CreateLandingPageRequest
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof CreateLandingPageRequest
   */
  backgroundImageUrl: string;
  /**
   *
   * @type {string}
   * @memberof CreateLandingPageRequest
   */
  markdownContent: string;
  /**
   *
   * @type {string}
   * @memberof CreateLandingPageRequest
   */
  welcomeImageUrl?: string;
  /**
   *
   * @type {WelcomeScreen}
   * @memberof CreateLandingPageRequest
   */
  welcomeScreen?: WelcomeScreen;
  /**
   *
   * @type {string}
   * @memberof CreateLandingPageRequest
   */
  customPath?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateLandingPageRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface CreateMenuRequest
 */
export interface CreateMenuRequest {
  /**
   *
   * @type {string}
   * @memberof CreateMenuRequest
   */
  qrName: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof CreateMenuRequest
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof CreateMenuRequest
   */
  coverImageUrl: string;
  /**
   *
   * @type {string}
   * @memberof CreateMenuRequest
   */
  companyLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMenuRequest
   */
  menuName: string;
  /**
   *
   * @type {string}
   * @memberof CreateMenuRequest
   */
  description?: string;
  /**
   *
   * @type {Array<MenuOpeningHours>}
   * @memberof CreateMenuRequest
   */
  openingHours?: Array<MenuOpeningHours>;
  /**
   *
   * @type {ContactInfo}
   * @memberof CreateMenuRequest
   */
  contactInfo?: ContactInfo;
  /**
   *
   * @type {string}
   * @memberof CreateMenuRequest
   */
  customPath?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateMenuRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface CreatePlanRequest
 */
export interface CreatePlanRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof CreatePlanRequest
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  timeUnit?: CreatePlanRequestTimeUnitEnum;
  /**
   *
   * @type {number}
   * @memberof CreatePlanRequest
   */
  value?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreatePlanRequest
   */
  trial?: boolean;
}

export const CreatePlanRequestTimeUnitEnum = {
  Date: "DATE",
  Month: "MONTH",
} as const;

export type CreatePlanRequestTimeUnitEnum =
  (typeof CreatePlanRequestTimeUnitEnum)[keyof typeof CreatePlanRequestTimeUnitEnum];

/**
 *
 * @export
 * @interface CreateStaticWebsiteRequest
 */
export interface CreateStaticWebsiteRequest {
  /**
   *
   * @type {string}
   * @memberof CreateStaticWebsiteRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof CreateStaticWebsiteRequest
   */
  url: string;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateStaticWebsiteRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface CreateTemplateRequest
 */
export interface CreateTemplateRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTemplateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateTemplateRequest
   */
  type: CreateTemplateRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreateTemplateRequest
   */
  content: string;
}

export const CreateTemplateRequestTypeEnum = {
  VietQr: "VIET_QR",
  Common: "COMMON",
} as const;

export type CreateTemplateRequestTypeEnum =
  (typeof CreateTemplateRequestTypeEnum)[keyof typeof CreateTemplateRequestTypeEnum];

/**
 *
 * @export
 * @interface CreateTextQrRequest
 */
export interface CreateTextQrRequest {
  /**
   *
   * @type {string}
   * @memberof CreateTextQrRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof CreateTextQrRequest
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateTextQrRequest
   */
  customPath?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateTextQrRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface CreateVCardRequest
 */
export interface CreateVCardRequest {
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  url?: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof CreateVCardRequest
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  lastName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateVCardRequest
   */
  phones?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateVCardRequest
   */
  emails?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateVCardRequest
   */
  websites?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  brandLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  backgroundUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  summary?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateVCardRequest
   */
  images?: Array<string>;
  /**
   *
   * @type {Array<SocialNetwork>}
   * @memberof CreateVCardRequest
   */
  socialNetworks?: Array<SocialNetwork>;
  /**
   *
   * @type {string}
   * @memberof CreateVCardRequest
   */
  customPath?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateVCardRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface CreateVietQrRequest
 */
export interface CreateVietQrRequest {
  /**
   *
   * @type {string}
   * @memberof CreateVietQrRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof CreateVietQrRequest
   */
  bankBin: string;
  /**
   *
   * @type {string}
   * @memberof CreateVietQrRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof CreateVietQrRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof CreateVietQrRequest
   */
  templateId?: string;
  /**
   *
   * @type {number}
   * @memberof CreateVietQrRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof CreateVietQrRequest
   */
  content?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateVietQrRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface CreateWebsiteRequest
 */
export interface CreateWebsiteRequest {
  /**
   *
   * @type {string}
   * @memberof CreateWebsiteRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof CreateWebsiteRequest
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof CreateWebsiteRequest
   */
  customPath?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateWebsiteRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface CreateWirelessRequest
 */
export interface CreateWirelessRequest {
  /**
   *
   * @type {string}
   * @memberof CreateWirelessRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof CreateWirelessRequest
   */
  networkName: string;
  /**
   *
   * @type {string}
   * @memberof CreateWirelessRequest
   */
  networkPassword: string;
  /**
   *
   * @type {string}
   * @memberof CreateWirelessRequest
   */
  encryptType: CreateWirelessRequestEncryptTypeEnum;
  /**
   *
   * @type {QRStyle}
   * @memberof CreateWirelessRequest
   */
  qrStyle?: QRStyle;
}

export const CreateWirelessRequestEncryptTypeEnum = {
  Wep: "WEP",
  Wpa: "WPA",
} as const;

export type CreateWirelessRequestEncryptTypeEnum =
  (typeof CreateWirelessRequestEncryptTypeEnum)[keyof typeof CreateWirelessRequestEncryptTypeEnum];

/**
 *
 * @export
 * @interface DashboardResponse
 */
export interface DashboardResponse {
  /**
   *
   * @type {number}
   * @memberof DashboardResponse
   */
  totalQrCode?: number;
  /**
   *
   * @type {number}
   * @memberof DashboardResponse
   */
  totalScan?: number;
  /**
   *
   * @type {Array<DayTransactionReport>}
   * @memberof DashboardResponse
   */
  daysReports?: Array<DayTransactionReport>;
}
/**
 *
 * @export
 * @interface DayTransactionReport
 */
export interface DayTransactionReport {
  /**
   *
   * @type {number}
   * @memberof DayTransactionReport
   */
  totalScan?: number;
  /**
   *
   * @type {string}
   * @memberof DayTransactionReport
   */
  date?: string;
}
/**
 *
 * @export
 * @interface DotStyle
 */
export interface DotStyle {
  /**
   *
   * @type {string}
   * @memberof DotStyle
   */
  backgroundColor?: string;
  /**
   *
   * @type {ColorStyle}
   * @memberof DotStyle
   */
  color?: ColorStyle;
  /**
   *
   * @type {string}
   * @memberof DotStyle
   */
  style?: string;
}
/**
 *
 * @export
 * @interface EncryptedBodyRequest
 */
export interface EncryptedBodyRequest {
  /**
   *
   * @type {string}
   * @memberof EncryptedBodyRequest
   */
  data?: string;
}
/**
 *
 * @export
 * @interface FolderResponse
 */
export interface FolderResponse {
  /**
   *
   * @type {string}
   * @memberof FolderResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FolderResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FolderResponse
   */
  createDate?: string;
}
/**
 *
 * @export
 * @interface FrameStyle
 */
export interface FrameStyle {
  /**
   *
   * @type {ColorStyle}
   * @memberof FrameStyle
   */
  backgroundColor?: ColorStyle;
  /**
   *
   * @type {ColorStyle}
   * @memberof FrameStyle
   */
  color?: ColorStyle;
  /**
   *
   * @type {number}
   * @memberof FrameStyle
   */
  fontSize?: number;
  /**
   *
   * @type {number}
   * @memberof FrameStyle
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FrameStyle
   */
  text?: string;
}
/**
 *
 * @export
 * @interface LandingPageResponse
 */
export interface LandingPageResponse {
  /**
   *
   * @type {string}
   * @memberof LandingPageResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof LandingPageResponse
   */
  qrName?: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof LandingPageResponse
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof LandingPageResponse
   */
  backgroundImageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof LandingPageResponse
   */
  markdownContent?: string;
  /**
   *
   * @type {string}
   * @memberof LandingPageResponse
   */
  welcomeImageUrl?: string;
  /**
   *
   * @type {WelcomeScreen}
   * @memberof LandingPageResponse
   */
  welcomeScreen?: WelcomeScreen;
  /**
   *
   * @type {QRStyle}
   * @memberof LandingPageResponse
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface ListQrCodeResponse
 */
export interface ListQrCodeResponse {
  /**
   *
   * @type {PageResponseQrCodeResponse}
   * @memberof ListQrCodeResponse
   */
  qrCodes?: PageResponseQrCodeResponse;
}
/**
 *
 * @export
 * @interface ListResponseFolderResponse
 */
export interface ListResponseFolderResponse {
  /**
   *
   * @type {Array<FolderResponse>}
   * @memberof ListResponseFolderResponse
   */
  contents?: Array<FolderResponse>;
}
/**
 *
 * @export
 * @interface ListResponsePlanResponse
 */
export interface ListResponsePlanResponse {
  /**
   *
   * @type {Array<PlanResponse>}
   * @memberof ListResponsePlanResponse
   */
  contents?: Array<PlanResponse>;
}
/**
 *
 * @export
 * @interface ListResponseTemplateResponse
 */
export interface ListResponseTemplateResponse {
  /**
   *
   * @type {Array<TemplateResponse>}
   * @memberof ListResponseTemplateResponse
   */
  contents?: Array<TemplateResponse>;
}
/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
  /**
   *
   * @type {string}
   * @memberof LogoutRequest
   */
  refreshToken?: string;
}
/**
 *
 * @export
 * @interface LogoutResponse
 */
export interface LogoutResponse {
  /**
   *
   * @type {boolean}
   * @memberof LogoutResponse
   */
  logout?: boolean;
}
/**
 *
 * @export
 * @interface MenuOpeningHours
 */
export interface MenuOpeningHours {
  /**
   *
   * @type {string}
   * @memberof MenuOpeningHours
   */
  dateType?: MenuOpeningHoursDateTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MenuOpeningHours
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof MenuOpeningHours
   */
  to?: string;
}

export const MenuOpeningHoursDateTypeEnum = {
  Monday: "MONDAY",
  Tuesday: "TUESDAY",
  Wednesday: "WEDNESDAY",
  Thursday: "THURSDAY",
  Friday: "FRIDAY",
  Saturday: "SATURDAY",
  Sunday: "SUNDAY",
} as const;

export type MenuOpeningHoursDateTypeEnum =
  (typeof MenuOpeningHoursDateTypeEnum)[keyof typeof MenuOpeningHoursDateTypeEnum];

/**
 *
 * @export
 * @interface MenuResponse
 */
export interface MenuResponse {
  /**
   *
   * @type {string}
   * @memberof MenuResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof MenuResponse
   */
  qrName?: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof MenuResponse
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof MenuResponse
   */
  coverImageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof MenuResponse
   */
  companyLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof MenuResponse
   */
  menuName?: string;
  /**
   *
   * @type {string}
   * @memberof MenuResponse
   */
  description?: string;
  /**
   *
   * @type {Array<MenuOpeningHours>}
   * @memberof MenuResponse
   */
  openingHours?: Array<MenuOpeningHours>;
  /**
   *
   * @type {ContactInfo}
   * @memberof MenuResponse
   */
  contactInfo?: ContactInfo;
  /**
   *
   * @type {string}
   * @memberof MenuResponse
   */
  qrContent?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof MenuResponse
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface PageResponseQrCodeResponse
 */
export interface PageResponseQrCodeResponse {
  /**
   *
   * @type {number}
   * @memberof PageResponseQrCodeResponse
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseQrCodeResponse
   */
  pageSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseQrCodeResponse
   */
  totalSize?: number;
  /**
   *
   * @type {number}
   * @memberof PageResponseQrCodeResponse
   */
  totalPage?: number;
  /**
   *
   * @type {Array<QrCodeResponse>}
   * @memberof PageResponseQrCodeResponse
   */
  items?: Array<QrCodeResponse>;
}
/**
 *
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentRequest
   */
  planId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRequest
   */
  paymentMethod: PaymentRequestPaymentMethodEnum;
}

export const PaymentRequestPaymentMethodEnum = {
  KlbPay: "KLB_PAY",
  Momo: "MOMO",
  Paypal: "PAYPAL",
  ZaloPay: "ZALO_PAY",
} as const;

export type PaymentRequestPaymentMethodEnum =
  (typeof PaymentRequestPaymentMethodEnum)[keyof typeof PaymentRequestPaymentMethodEnum];

/**
 *
 * @export
 * @interface PaymentResponse
 */
export interface PaymentResponse {
  /**
   *
   * @type {string}
   * @memberof PaymentResponse
   */
  orderId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentResponse
   */
  payLink?: string;
}
/**
 *
 * @export
 * @interface PlanResponse
 */
export interface PlanResponse {
  /**
   *
   * @type {string}
   * @memberof PlanResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PlanResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PlanResponse
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PlanResponse
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof PlanResponse
   */
  timeUnit?: PlanResponseTimeUnitEnum;
  /**
   *
   * @type {number}
   * @memberof PlanResponse
   */
  value?: number;
  /**
   *
   * @type {boolean}
   * @memberof PlanResponse
   */
  enable?: boolean;
  /**
   *
   * @type {string}
   * @memberof PlanResponse
   */
  createDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof PlanResponse
   */
  trial?: boolean;
}

export const PlanResponseTimeUnitEnum = {
  Date: "DATE",
  Month: "MONTH",
} as const;

export type PlanResponseTimeUnitEnum =
  (typeof PlanResponseTimeUnitEnum)[keyof typeof PlanResponseTimeUnitEnum];

/**
 *
 * @export
 * @interface PublicQrCodeResponse
 */
export interface PublicQrCodeResponse {
  /**
   *
   * @type {string}
   * @memberof PublicQrCodeResponse
   */
  sessionId?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQrCodeResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQrCodeResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PublicQrCodeResponse
   */
  type?: PublicQrCodeResponseTypeEnum;
  /**
   *
   * @type {QrCodeBase}
   * @memberof PublicQrCodeResponse
   */
  data?: QrCodeBase;
  /**
   *
   * @type {QRStyle}
   * @memberof PublicQrCodeResponse
   */
  qrStyle?: QRStyle;
}

export const PublicQrCodeResponseTypeEnum = {
  Website: "WEBSITE",
  Pdf: "PDF",
  ListOfLinks: "LIST_OF_LINKS",
  Pictures: "PICTURES",
  Video: "VIDEO",
  Business: "BUSINESS",
  Vcard: "VCARD",
  Menu: "MENU",
  App: "APP",
  Mp3: "MP3",
  Coupon: "COUPON",
  Feedback: "FEEDBACK",
  Playlist: "PLAYLIST",
  LandingPage: "LANDING_PAGE",
  Event: "EVENT",
  StaticWebsite: "STATIC_WEBSITE",
  StaticText: "STATIC_TEXT",
  StaticWireless: "STATIC_WIRELESS",
  StaticVietqr: "STATIC_VIETQR",
} as const;

export type PublicQrCodeResponseTypeEnum =
  (typeof PublicQrCodeResponseTypeEnum)[keyof typeof PublicQrCodeResponseTypeEnum];

/**
 *
 * @export
 * @interface QRStyle
 */
export interface QRStyle {
  /**
   *
   * @type {CornerStyle}
   * @memberof QRStyle
   */
  corner?: CornerStyle;
  /**
   *
   * @type {FrameStyle}
   * @memberof QRStyle
   */
  frame?: FrameStyle;
  /**
   *
   * @type {string}
   * @memberof QRStyle
   */
  image?: string;
  /**
   *
   * @type {ShapeStyle}
   * @memberof QRStyle
   */
  shape?: ShapeStyle;
  /**
   *
   * @type {DotStyle}
   * @memberof QRStyle
   */
  dot?: DotStyle;
}
/**
 *
 * @export
 * @interface QrCodeBase
 */
export interface QrCodeBase {
  /**
   *
   * @type {string}
   * @memberof QrCodeBase
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof QrCodeBase
   */
  type?: QrCodeBaseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof QrCodeBase
   */
  qrContent?: string;
}

export const QrCodeBaseTypeEnum = {
  Website: "WEBSITE",
  Pdf: "PDF",
  ListOfLinks: "LIST_OF_LINKS",
  Pictures: "PICTURES",
  Video: "VIDEO",
  Business: "BUSINESS",
  Vcard: "VCARD",
  Menu: "MENU",
  App: "APP",
  Mp3: "MP3",
  Coupon: "COUPON",
  Feedback: "FEEDBACK",
  Playlist: "PLAYLIST",
  LandingPage: "LANDING_PAGE",
  Event: "EVENT",
  StaticWebsite: "STATIC_WEBSITE",
  StaticText: "STATIC_TEXT",
  StaticWireless: "STATIC_WIRELESS",
  StaticVietqr: "STATIC_VIETQR",
} as const;

export type QrCodeBaseTypeEnum =
  (typeof QrCodeBaseTypeEnum)[keyof typeof QrCodeBaseTypeEnum];

/**
 *
 * @export
 * @interface QrCodeResponse
 */
export interface QrCodeResponse {
  /**
   *
   * @type {string}
   * @memberof QrCodeResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof QrCodeResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof QrCodeResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof QrCodeResponse
   */
  domain?: string;
  /**
   *
   * @type {string}
   * @memberof QrCodeResponse
   */
  type?: QrCodeResponseTypeEnum;
  /**
   *
   * @type {QrCodeBase}
   * @memberof QrCodeResponse
   */
  data?: QrCodeBase;
  /**
   *
   * @type {QRStyle}
   * @memberof QrCodeResponse
   */
  qrStyle?: QRStyle;
  /**
   *
   * @type {number}
   * @memberof QrCodeResponse
   */
  counter?: number;
  /**
   *
   * @type {string}
   * @memberof QrCodeResponse
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof QrCodeResponse
   */
  modifiedDate?: string;
}

export const QrCodeResponseTypeEnum = {
  Website: "WEBSITE",
  Pdf: "PDF",
  ListOfLinks: "LIST_OF_LINKS",
  Pictures: "PICTURES",
  Video: "VIDEO",
  Business: "BUSINESS",
  Vcard: "VCARD",
  Menu: "MENU",
  App: "APP",
  Mp3: "MP3",
  Coupon: "COUPON",
  Feedback: "FEEDBACK",
  Playlist: "PLAYLIST",
  LandingPage: "LANDING_PAGE",
  Event: "EVENT",
  StaticWebsite: "STATIC_WEBSITE",
  StaticText: "STATIC_TEXT",
  StaticWireless: "STATIC_WIRELESS",
  StaticVietqr: "STATIC_VIETQR",
} as const;

export type QrCodeResponseTypeEnum =
  (typeof QrCodeResponseTypeEnum)[keyof typeof QrCodeResponseTypeEnum];

/**
 *
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRequest
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface RegisterUserRequest
 */
export interface RegisterUserRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface ResetPasswordSubmitRequest
 */
export interface ResetPasswordSubmitRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordSubmitRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordSubmitRequest
   */
  session: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordSubmitRequest
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface ResponseBaseAccessTokenResponse
 */
export interface ResponseBaseAccessTokenResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseAccessTokenResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseAccessTokenResponse
   */
  message?: string;
  /**
   *
   * @type {AccessTokenResponse}
   * @memberof ResponseBaseAccessTokenResponse
   */
  data?: AccessTokenResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseDashboardResponse
 */
export interface ResponseBaseDashboardResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseDashboardResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseDashboardResponse
   */
  message?: string;
  /**
   *
   * @type {DashboardResponse}
   * @memberof ResponseBaseDashboardResponse
   */
  data?: DashboardResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseFolderResponse
 */
export interface ResponseBaseFolderResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseFolderResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseFolderResponse
   */
  message?: string;
  /**
   *
   * @type {FolderResponse}
   * @memberof ResponseBaseFolderResponse
   */
  data?: FolderResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLandingPageResponse
 */
export interface ResponseBaseLandingPageResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseLandingPageResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLandingPageResponse
   */
  message?: string;
  /**
   *
   * @type {LandingPageResponse}
   * @memberof ResponseBaseLandingPageResponse
   */
  data?: LandingPageResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListQrCodeResponse
 */
export interface ResponseBaseListQrCodeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseListQrCodeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListQrCodeResponse
   */
  message?: string;
  /**
   *
   * @type {ListQrCodeResponse}
   * @memberof ResponseBaseListQrCodeResponse
   */
  data?: ListQrCodeResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListResponseFolderResponse
 */
export interface ResponseBaseListResponseFolderResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseListResponseFolderResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponseFolderResponse
   */
  message?: string;
  /**
   *
   * @type {ListResponseFolderResponse}
   * @memberof ResponseBaseListResponseFolderResponse
   */
  data?: ListResponseFolderResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListResponsePlanResponse
 */
export interface ResponseBaseListResponsePlanResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseListResponsePlanResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponsePlanResponse
   */
  message?: string;
  /**
   *
   * @type {ListResponsePlanResponse}
   * @memberof ResponseBaseListResponsePlanResponse
   */
  data?: ListResponsePlanResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseListResponseTemplateResponse
 */
export interface ResponseBaseListResponseTemplateResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseListResponseTemplateResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseListResponseTemplateResponse
   */
  message?: string;
  /**
   *
   * @type {ListResponseTemplateResponse}
   * @memberof ResponseBaseListResponseTemplateResponse
   */
  data?: ListResponseTemplateResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseLogoutResponse
 */
export interface ResponseBaseLogoutResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseLogoutResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseLogoutResponse
   */
  message?: string;
  /**
   *
   * @type {LogoutResponse}
   * @memberof ResponseBaseLogoutResponse
   */
  data?: LogoutResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseMenuResponse
 */
export interface ResponseBaseMenuResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseMenuResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseMenuResponse
   */
  message?: string;
  /**
   *
   * @type {MenuResponse}
   * @memberof ResponseBaseMenuResponse
   */
  data?: MenuResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePaymentResponse
 */
export interface ResponseBasePaymentResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePaymentResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePaymentResponse
   */
  message?: string;
  /**
   *
   * @type {PaymentResponse}
   * @memberof ResponseBasePaymentResponse
   */
  data?: PaymentResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePlanResponse
 */
export interface ResponseBasePlanResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePlanResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePlanResponse
   */
  message?: string;
  /**
   *
   * @type {PlanResponse}
   * @memberof ResponseBasePlanResponse
   */
  data?: PlanResponse;
}
/**
 *
 * @export
 * @interface ResponseBasePublicQrCodeResponse
 */
export interface ResponseBasePublicQrCodeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBasePublicQrCodeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBasePublicQrCodeResponse
   */
  message?: string;
  /**
   *
   * @type {PublicQrCodeResponse}
   * @memberof ResponseBasePublicQrCodeResponse
   */
  data?: PublicQrCodeResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseQrCodeResponse
 */
export interface ResponseBaseQrCodeResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseQrCodeResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseQrCodeResponse
   */
  message?: string;
  /**
   *
   * @type {QrCodeResponse}
   * @memberof ResponseBaseQrCodeResponse
   */
  data?: QrCodeResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStaticWebsiteResponse
 */
export interface ResponseBaseStaticWebsiteResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseStaticWebsiteResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStaticWebsiteResponse
   */
  message?: string;
  /**
   *
   * @type {StaticWebsiteResponse}
   * @memberof ResponseBaseStaticWebsiteResponse
   */
  data?: StaticWebsiteResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseStatusResponse
 */
export interface ResponseBaseStatusResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseStatusResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseStatusResponse
   */
  message?: string;
  /**
   *
   * @type {StatusResponse}
   * @memberof ResponseBaseStatusResponse
   */
  data?: StatusResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseString
 */
export interface ResponseBaseString {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseString
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseString
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseString
   */
  data?: string;
}
/**
 *
 * @export
 * @interface ResponseBaseTemplateResponse
 */
export interface ResponseBaseTemplateResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseTemplateResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTemplateResponse
   */
  message?: string;
  /**
   *
   * @type {TemplateResponse}
   * @memberof ResponseBaseTemplateResponse
   */
  data?: TemplateResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseTextQrResponse
 */
export interface ResponseBaseTextQrResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseTextQrResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseTextQrResponse
   */
  message?: string;
  /**
   *
   * @type {TextQrResponse}
   * @memberof ResponseBaseTextQrResponse
   */
  data?: TextQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUploadImageResponse
 */
export interface ResponseBaseUploadImageResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUploadImageResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUploadImageResponse
   */
  message?: string;
  /**
   *
   * @type {UploadImageResponse}
   * @memberof ResponseBaseUploadImageResponse
   */
  data?: UploadImageResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUserDetailResponse
 */
export interface ResponseBaseUserDetailResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUserDetailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUserDetailResponse
   */
  message?: string;
  /**
   *
   * @type {UserDetailResponse}
   * @memberof ResponseBaseUserDetailResponse
   */
  data?: UserDetailResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseUserResponse
 */
export interface ResponseBaseUserResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseUserResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseUserResponse
   */
  message?: string;
  /**
   *
   * @type {UserResponse}
   * @memberof ResponseBaseUserResponse
   */
  data?: UserResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVCardResponse
 */
export interface ResponseBaseVCardResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseVCardResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVCardResponse
   */
  message?: string;
  /**
   *
   * @type {VCardResponse}
   * @memberof ResponseBaseVCardResponse
   */
  data?: VCardResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseVietQrResponse
 */
export interface ResponseBaseVietQrResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseVietQrResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseVietQrResponse
   */
  message?: string;
  /**
   *
   * @type {VietQrResponse}
   * @memberof ResponseBaseVietQrResponse
   */
  data?: VietQrResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseWebsiteResponse
 */
export interface ResponseBaseWebsiteResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseWebsiteResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseWebsiteResponse
   */
  message?: string;
  /**
   *
   * @type {WebsiteResponse}
   * @memberof ResponseBaseWebsiteResponse
   */
  data?: WebsiteResponse;
}
/**
 *
 * @export
 * @interface ResponseBaseWirelessResponse
 */
export interface ResponseBaseWirelessResponse {
  /**
   *
   * @type {number}
   * @memberof ResponseBaseWirelessResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBaseWirelessResponse
   */
  message?: string;
  /**
   *
   * @type {WirelessResponse}
   * @memberof ResponseBaseWirelessResponse
   */
  data?: WirelessResponse;
}
/**
 *
 * @export
 * @interface ShapeStyle
 */
export interface ShapeStyle {
  /**
   *
   * @type {string}
   * @memberof ShapeStyle
   */
  backgroundColor?: string;
  /**
   *
   * @type {ColorStyle}
   * @memberof ShapeStyle
   */
  color?: ColorStyle;
  /**
   *
   * @type {string}
   * @memberof ShapeStyle
   */
  style?: string;
}
/**
 *
 * @export
 * @interface SocialNetwork
 */
export interface SocialNetwork {
  /**
   *
   * @type {string}
   * @memberof SocialNetwork
   */
  type?: SocialNetworkTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SocialNetwork
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof SocialNetwork
   */
  text?: string;
}

export const SocialNetworkTypeEnum = {
  Facebook: "FACEBOOK",
  Skype: "SKYPE",
  Zalo: "ZALO",
  Github: "GITHUB",
  Google: "GOOGLE",
  Linkedin: "LINKEDIN",
  Snapchat: "SNAPCHAT",
  Twitter: "TWITTER",
  Youtube: "YOUTUBE",
  Instagram: "INSTAGRAM",
  Tiktok: "TIKTOK",
  Whatsapp: "WHATSAPP",
  Telegram: "TELEGRAM",
  Messenger: "MESSENGER",
} as const;

export type SocialNetworkTypeEnum =
  (typeof SocialNetworkTypeEnum)[keyof typeof SocialNetworkTypeEnum];

/**
 *
 * @export
 * @interface StaticWebsiteResponse
 */
export interface StaticWebsiteResponse {
  /**
   *
   * @type {string}
   * @memberof StaticWebsiteResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof StaticWebsiteResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof StaticWebsiteResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof StaticWebsiteResponse
   */
  qrContent?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof StaticWebsiteResponse
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof StatusResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  planId?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  payTime?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  expireTime?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  orderId?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  createDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionDto
   */
  trial?: boolean;
}
/**
 *
 * @export
 * @interface TemplateResponse
 */
export interface TemplateResponse {
  /**
   *
   * @type {string}
   * @memberof TemplateResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TemplateResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TemplateResponse
   */
  type?: TemplateResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TemplateResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof TemplateResponse
   */
  createDate?: string;
}

export const TemplateResponseTypeEnum = {
  VietQr: "VIET_QR",
  Common: "COMMON",
} as const;

export type TemplateResponseTypeEnum =
  (typeof TemplateResponseTypeEnum)[keyof typeof TemplateResponseTypeEnum];

/**
 *
 * @export
 * @interface TextQrResponse
 */
export interface TextQrResponse {
  /**
   *
   * @type {string}
   * @memberof TextQrResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TextQrResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof TextQrResponse
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof TextQrResponse
   */
  content?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof TextQrResponse
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdateAvatarRequest
 */
export interface UpdateAvatarRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateAvatarRequest
   */
  url: string;
}
/**
 *
 * @export
 * @interface UpdateBillDataRequest
 */
export interface UpdateBillDataRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  taxId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof UpdateBillDataRequest
   */
  currency: UpdateBillDataRequestCurrencyEnum;
}

export const UpdateBillDataRequestCurrencyEnum = {
  Vnd: "VND",
  Usd: "USD",
} as const;

export type UpdateBillDataRequestCurrencyEnum =
  (typeof UpdateBillDataRequestCurrencyEnum)[keyof typeof UpdateBillDataRequestCurrencyEnum];

/**
 *
 * @export
 * @interface UpdateFolderRequest
 */
export interface UpdateFolderRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateFolderRequest
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateLandingPageRequest
 */
export interface UpdateLandingPageRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateLandingPageRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLandingPageRequest
   */
  qrName: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof UpdateLandingPageRequest
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof UpdateLandingPageRequest
   */
  backgroundImageUrl: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLandingPageRequest
   */
  markdownContent: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLandingPageRequest
   */
  welcomeImageUrl?: string;
  /**
   *
   * @type {WelcomeScreen}
   * @memberof UpdateLandingPageRequest
   */
  welcomeScreen?: WelcomeScreen;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateLandingPageRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdateMenuRequest
 */
export interface UpdateMenuRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateMenuRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMenuRequest
   */
  qrName: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof UpdateMenuRequest
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof UpdateMenuRequest
   */
  coverImageUrl: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMenuRequest
   */
  companyLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMenuRequest
   */
  menuName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateMenuRequest
   */
  description?: string;
  /**
   *
   * @type {Array<MenuOpeningHours>}
   * @memberof UpdateMenuRequest
   */
  openingHours?: Array<MenuOpeningHours>;
  /**
   *
   * @type {ContactInfo}
   * @memberof UpdateMenuRequest
   */
  contactInfo?: ContactInfo;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateMenuRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdatePlanRequest
 */
export interface UpdatePlanRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof UpdatePlanRequest
   */
  price?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePlanRequest
   */
  enable?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  timeUnit?: UpdatePlanRequestTimeUnitEnum;
  /**
   *
   * @type {number}
   * @memberof UpdatePlanRequest
   */
  value?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePlanRequest
   */
  trial?: boolean;
}

export const UpdatePlanRequestTimeUnitEnum = {
  Date: "DATE",
  Month: "MONTH",
} as const;

export type UpdatePlanRequestTimeUnitEnum =
  (typeof UpdatePlanRequestTimeUnitEnum)[keyof typeof UpdatePlanRequestTimeUnitEnum];

/**
 *
 * @export
 * @interface UpdateStaticWebsiteRequest
 */
export interface UpdateStaticWebsiteRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateStaticWebsiteRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticWebsiteRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateStaticWebsiteRequest
   */
  url: string;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateStaticWebsiteRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdateTemplateRequest
 */
export interface UpdateTemplateRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateTemplateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTemplateRequest
   */
  type: UpdateTemplateRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateTemplateRequest
   */
  content: string;
}

export const UpdateTemplateRequestTypeEnum = {
  VietQr: "VIET_QR",
  Common: "COMMON",
} as const;

export type UpdateTemplateRequestTypeEnum =
  (typeof UpdateTemplateRequestTypeEnum)[keyof typeof UpdateTemplateRequestTypeEnum];

/**
 *
 * @export
 * @interface UpdateTextRequest
 */
export interface UpdateTextRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateTextRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTextRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTextRequest
   */
  content: string;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateTextRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdateUserInfoRequest
 */
export interface UpdateUserInfoRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserInfoRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserInfoRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserInfoRequest
   */
  phone: string;
}
/**
 *
 * @export
 * @interface UpdateVCardRequest
 */
export interface UpdateVCardRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  url?: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof UpdateVCardRequest
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  lastName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateVCardRequest
   */
  phones?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateVCardRequest
   */
  emails?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateVCardRequest
   */
  websites?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  brandLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  backgroundUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVCardRequest
   */
  summary?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateVCardRequest
   */
  images?: Array<string>;
  /**
   *
   * @type {Array<SocialNetwork>}
   * @memberof UpdateVCardRequest
   */
  socialNetworks?: Array<SocialNetwork>;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateVCardRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdateVietQrRequest
 */
export interface UpdateVietQrRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateVietQrRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVietQrRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVietQrRequest
   */
  bankBin: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVietQrRequest
   */
  accountNo: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVietQrRequest
   */
  accountName: string;
  /**
   *
   * @type {number}
   * @memberof UpdateVietQrRequest
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateVietQrRequest
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateVietQrRequest
   */
  templateId?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateVietQrRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdateWebsiteRequest
 */
export interface UpdateWebsiteRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateWebsiteRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWebsiteRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWebsiteRequest
   */
  url: string;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateWebsiteRequest
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface UpdateWirelessRequest
 */
export interface UpdateWirelessRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateWirelessRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWirelessRequest
   */
  qrName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWirelessRequest
   */
  networkName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWirelessRequest
   */
  networkPassword: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWirelessRequest
   */
  encryptType: UpdateWirelessRequestEncryptTypeEnum;
  /**
   *
   * @type {QRStyle}
   * @memberof UpdateWirelessRequest
   */
  qrStyle?: QRStyle;
}

export const UpdateWirelessRequestEncryptTypeEnum = {
  Wep: "WEP",
  Wpa: "WPA",
} as const;

export type UpdateWirelessRequestEncryptTypeEnum =
  (typeof UpdateWirelessRequestEncryptTypeEnum)[keyof typeof UpdateWirelessRequestEncryptTypeEnum];

/**
 *
 * @export
 * @interface UploadImageResponse
 */
export interface UploadImageResponse {
  /**
   *
   * @type {string}
   * @memberof UploadImageResponse
   */
  url?: string;
}
/**
 *
 * @export
 * @interface UserDetailResponse
 */
export interface UserDetailResponse {
  /**
   *
   * @type {string}
   * @memberof UserDetailResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailResponse
   */
  createDate?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailResponse
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof UserDetailResponse
   */
  role?: UserDetailResponseRoleEnum;
  /**
   *
   * @type {BillDataDto}
   * @memberof UserDetailResponse
   */
  billingData?: BillDataDto;
  /**
   *
   * @type {SubscriptionDto}
   * @memberof UserDetailResponse
   */
  subscription?: SubscriptionDto;
}

export const UserDetailResponseRoleEnum = {
  Admin: "ADMIN",
  Owner: "OWNER",
  User: "USER",
} as const;

export type UserDetailResponseRoleEnum =
  (typeof UserDetailResponseRoleEnum)[keyof typeof UserDetailResponseRoleEnum];

/**
 *
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  projectName?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  createDate?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  role?: UserResponseRoleEnum;
}

export const UserResponseRoleEnum = {
  Admin: "ADMIN",
  Owner: "OWNER",
  User: "USER",
} as const;

export type UserResponseRoleEnum =
  (typeof UserResponseRoleEnum)[keyof typeof UserResponseRoleEnum];

/**
 *
 * @export
 * @interface VCardResponse
 */
export interface VCardResponse {
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  url?: string;
  /**
   *
   * @type {ContentStyle}
   * @memberof VCardResponse
   */
  contentStyle?: ContentStyle;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  lastName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof VCardResponse
   */
  phones?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof VCardResponse
   */
  emails?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof VCardResponse
   */
  websites?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  brandLogoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  backgroundUrl?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof VCardResponse
   */
  summary?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof VCardResponse
   */
  images?: Array<string>;
  /**
   *
   * @type {Array<SocialNetwork>}
   * @memberof VCardResponse
   */
  socialNetworks?: Array<SocialNetwork>;
  /**
   *
   * @type {QRStyle}
   * @memberof VCardResponse
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface VietQrResponse
 */
export interface VietQrResponse {
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  qrContent?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  bankBin?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  accountNo?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  accountName?: string;
  /**
   *
   * @type {number}
   * @memberof VietQrResponse
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof VietQrResponse
   */
  templateId?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof VietQrResponse
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface WebsiteResponse
 */
export interface WebsiteResponse {
  /**
   *
   * @type {string}
   * @memberof WebsiteResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof WebsiteResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof WebsiteResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof WebsiteResponse
   */
  qrContent?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof WebsiteResponse
   */
  qrStyle?: QRStyle;
}
/**
 *
 * @export
 * @interface WelcomeScreen
 */
export interface WelcomeScreen {
  /**
   *
   * @type {string}
   * @memberof WelcomeScreen
   */
  imageUrl?: string;
  /**
   *
   * @type {number}
   * @memberof WelcomeScreen
   */
  timeShow?: number;
}
/**
 *
 * @export
 * @interface WirelessResponse
 */
export interface WirelessResponse {
  /**
   *
   * @type {string}
   * @memberof WirelessResponse
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof WirelessResponse
   */
  qrName?: string;
  /**
   *
   * @type {string}
   * @memberof WirelessResponse
   */
  networkName?: string;
  /**
   *
   * @type {string}
   * @memberof WirelessResponse
   */
  networkPassword?: string;
  /**
   *
   * @type {string}
   * @memberof WirelessResponse
   */
  encryptType?: WirelessResponseEncryptTypeEnum;
  /**
   *
   * @type {string}
   * @memberof WirelessResponse
   */
  qrContent?: string;
  /**
   *
   * @type {QRStyle}
   * @memberof WirelessResponse
   */
  qrStyle?: QRStyle;
}

export const WirelessResponseEncryptTypeEnum = {
  Wep: "WEP",
  Wpa: "WPA",
} as const;

export type WirelessResponseEncryptTypeEnum =
  (typeof WirelessResponseEncryptTypeEnum)[keyof typeof WirelessResponseEncryptTypeEnum];

/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * - login với username là email hoặc số điện thoại
     * @summary Admin login
     * @param {AdminLoginRequest} adminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogin: async (
      adminLoginRequest: AdminLoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminLoginRequest' is not null or undefined
      assertParamExists("adminLogin", "adminLoginRequest", adminLoginRequest);
      const localVarPath = `/api/auth/v1/login/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminLoginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary logout
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (
      logoutRequest: LogoutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'logoutRequest' is not null or undefined
      assertParamExists("logout", "logoutRequest", logoutRequest);
      const localVarPath = `/api/auth/v1/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        logoutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary refresh token
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (
      refreshTokenRequest: RefreshTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshTokenRequest' is not null or undefined
      assertParamExists(
        "refreshToken",
        "refreshTokenRequest",
        refreshTokenRequest,
      );
      const localVarPath = `/api/auth/v1/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * - login với username là email hoặc số điện thoại
     * @summary user login
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogin: async (
      loginRequest: LoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists("userLogin", "loginRequest", loginRequest);
      const localVarPath = `/api/auth/v1/login/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AuthControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * - login với username là email hoặc số điện thoại
     * @summary Admin login
     * @param {AdminLoginRequest} adminLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminLogin(
      adminLoginRequest: AdminLoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminLogin(
        adminLoginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary logout
     * @param {LogoutRequest} logoutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      logoutRequest: LogoutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLogoutResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(
        logoutRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary refresh token
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      refreshTokenRequest: RefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(
        refreshTokenRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * - login với username là email hoặc số điện thoại
     * @summary user login
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userLogin(
      loginRequest: LoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userLogin(
        loginRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthControllerApiFp(configuration);
  return {
    /**
     * - login với username là email hoặc số điện thoại
     * @summary Admin login
     * @param {AuthControllerApiAdminLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogin(
      requestParameters: AuthControllerApiAdminLoginRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .adminLogin(requestParameters.adminLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary logout
     * @param {AuthControllerApiLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(
      requestParameters: AuthControllerApiLogoutRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLogoutResponse> {
      return localVarFp
        .logout(requestParameters.logoutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary refresh token
     * @param {AuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(
      requestParameters: AuthControllerApiRefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .refreshToken(requestParameters.refreshTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * - login với username là email hoặc số điện thoại
     * @summary user login
     * @param {AuthControllerApiUserLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userLogin(
      requestParameters: AuthControllerApiUserLoginRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .userLogin(requestParameters.loginRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for adminLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiAdminLoginRequest
 */
export interface AuthControllerApiAdminLoginRequest {
  /**
   *
   * @type {AdminLoginRequest}
   * @memberof AuthControllerApiAdminLogin
   */
  readonly adminLoginRequest: AdminLoginRequest;
}

/**
 * Request parameters for logout operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiLogoutRequest
 */
export interface AuthControllerApiLogoutRequest {
  /**
   *
   * @type {LogoutRequest}
   * @memberof AuthControllerApiLogout
   */
  readonly logoutRequest: LogoutRequest;
}

/**
 * Request parameters for refreshToken operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiRefreshTokenRequest
 */
export interface AuthControllerApiRefreshTokenRequest {
  /**
   *
   * @type {RefreshTokenRequest}
   * @memberof AuthControllerApiRefreshToken
   */
  readonly refreshTokenRequest: RefreshTokenRequest;
}

/**
 * Request parameters for userLogin operation in AuthControllerApi.
 * @export
 * @interface AuthControllerApiUserLoginRequest
 */
export interface AuthControllerApiUserLoginRequest {
  /**
   *
   * @type {LoginRequest}
   * @memberof AuthControllerApiUserLogin
   */
  readonly loginRequest: LoginRequest;
}

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
  /**
   * - login với username là email hoặc số điện thoại
   * @summary Admin login
   * @param {AuthControllerApiAdminLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public adminLogin(
    requestParameters: AuthControllerApiAdminLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .adminLogin(requestParameters.adminLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary logout
   * @param {AuthControllerApiLogoutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public logout(
    requestParameters: AuthControllerApiLogoutRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .logout(requestParameters.logoutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary refresh token
   * @param {AuthControllerApiRefreshTokenRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public refreshToken(
    requestParameters: AuthControllerApiRefreshTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .refreshToken(requestParameters.refreshTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * - login với username là email hoặc số điện thoại
   * @summary user login
   * @param {AuthControllerApiUserLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthControllerApi
   */
  public userLogin(
    requestParameters: AuthControllerApiUserLoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthControllerApiFp(this.configuration)
      .userLogin(requestParameters.loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DynamicQrControllerApi - axios parameter creator
 * @export
 */
export const DynamicQrControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Tạo landing page qrcode
     * @summary Tạo landing page qrcode
     * @param {CreateLandingPageRequest} createLandingPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLandingPage: async (
      createLandingPageRequest: CreateLandingPageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createLandingPageRequest' is not null or undefined
      assertParamExists(
        "createLandingPage",
        "createLandingPageRequest",
        createLandingPageRequest,
      );
      const localVarPath = `/api/dynamicQr/v1/landingPage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLandingPageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo menu qrcode
     * @summary Tạo menu qrcode
     * @param {CreateMenuRequest} createMenuRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMenu: async (
      createMenuRequest: CreateMenuRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createMenuRequest' is not null or undefined
      assertParamExists("createMenu", "createMenuRequest", createMenuRequest);
      const localVarPath = `/api/dynamicQr/v1/menu`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMenuRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo vcard qrcode
     * @summary Tạo vcard qrcode
     * @param {CreateVCardRequest} createVCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVCard: async (
      createVCardRequest: CreateVCardRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createVCardRequest' is not null or undefined
      assertParamExists(
        "createVCard",
        "createVCardRequest",
        createVCardRequest,
      );
      const localVarPath = `/api/dynamicQr/v1/vcard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createVCardRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo website qrcode
     * @summary Tạo website qrcode
     * @param {CreateWebsiteRequest} createWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWebsite: async (
      createWebsiteRequest: CreateWebsiteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWebsiteRequest' is not null or undefined
      assertParamExists(
        "createWebsite",
        "createWebsiteRequest",
        createWebsiteRequest,
      );
      const localVarPath = `/api/dynamicQr/v1/website`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWebsiteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật landing page qrcode
     * @summary Cập nhật landing page qrcode
     * @param {UpdateLandingPageRequest} updateLandingPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLandingPage: async (
      updateLandingPageRequest: UpdateLandingPageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateLandingPageRequest' is not null or undefined
      assertParamExists(
        "updateLandingPage",
        "updateLandingPageRequest",
        updateLandingPageRequest,
      );
      const localVarPath = `/api/dynamicQr/v1/landingPage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLandingPageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật menu qrcode
     * @summary Cập nhật menu qrcode
     * @param {UpdateMenuRequest} updateMenuRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMenu: async (
      updateMenuRequest: UpdateMenuRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateMenuRequest' is not null or undefined
      assertParamExists("updateMenu", "updateMenuRequest", updateMenuRequest);
      const localVarPath = `/api/dynamicQr/v1/menu`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMenuRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật vcard qrcode
     * @summary Cập nhật vcard qrcode
     * @param {UpdateVCardRequest} updateVCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVCard: async (
      updateVCardRequest: UpdateVCardRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateVCardRequest' is not null or undefined
      assertParamExists(
        "updateVCard",
        "updateVCardRequest",
        updateVCardRequest,
      );
      const localVarPath = `/api/dynamicQr/v1/vcard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateVCardRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật website qrcode
     * @summary Cập nhật website qrcode
     * @param {UpdateWebsiteRequest} updateWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWebsite: async (
      updateWebsiteRequest: UpdateWebsiteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateWebsiteRequest' is not null or undefined
      assertParamExists(
        "updateWebsite",
        "updateWebsiteRequest",
        updateWebsiteRequest,
      );
      const localVarPath = `/api/dynamicQr/v1/website`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateWebsiteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DynamicQrControllerApi - functional programming interface
 * @export
 */
export const DynamicQrControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DynamicQrControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Tạo landing page qrcode
     * @summary Tạo landing page qrcode
     * @param {CreateLandingPageRequest} createLandingPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLandingPage(
      createLandingPageRequest: CreateLandingPageRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLandingPageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createLandingPage(
          createLandingPageRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo menu qrcode
     * @summary Tạo menu qrcode
     * @param {CreateMenuRequest} createMenuRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMenu(
      createMenuRequest: CreateMenuRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMenuResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMenu(
        createMenuRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo vcard qrcode
     * @summary Tạo vcard qrcode
     * @param {CreateVCardRequest} createVCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createVCard(
      createVCardRequest: CreateVCardRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVCardResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVCard(
        createVCardRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo website qrcode
     * @summary Tạo website qrcode
     * @param {CreateWebsiteRequest} createWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWebsite(
      createWebsiteRequest: CreateWebsiteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseWebsiteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWebsite(
        createWebsiteRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật landing page qrcode
     * @summary Cập nhật landing page qrcode
     * @param {UpdateLandingPageRequest} updateLandingPageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLandingPage(
      updateLandingPageRequest: UpdateLandingPageRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseLandingPageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLandingPage(
          updateLandingPageRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật menu qrcode
     * @summary Cập nhật menu qrcode
     * @param {UpdateMenuRequest} updateMenuRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMenu(
      updateMenuRequest: UpdateMenuRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseMenuResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMenu(
        updateMenuRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật vcard qrcode
     * @summary Cập nhật vcard qrcode
     * @param {UpdateVCardRequest} updateVCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateVCard(
      updateVCardRequest: UpdateVCardRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVCardResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateVCard(
        updateVCardRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật website qrcode
     * @summary Cập nhật website qrcode
     * @param {UpdateWebsiteRequest} updateWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWebsite(
      updateWebsiteRequest: UpdateWebsiteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseWebsiteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebsite(
        updateWebsiteRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DynamicQrControllerApi - factory interface
 * @export
 */
export const DynamicQrControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DynamicQrControllerApiFp(configuration);
  return {
    /**
     * Tạo landing page qrcode
     * @summary Tạo landing page qrcode
     * @param {DynamicQrControllerApiCreateLandingPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLandingPage(
      requestParameters: DynamicQrControllerApiCreateLandingPageRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLandingPageResponse> {
      return localVarFp
        .createLandingPage(requestParameters.createLandingPageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo menu qrcode
     * @summary Tạo menu qrcode
     * @param {DynamicQrControllerApiCreateMenuRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMenu(
      requestParameters: DynamicQrControllerApiCreateMenuRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseMenuResponse> {
      return localVarFp
        .createMenu(requestParameters.createMenuRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo vcard qrcode
     * @summary Tạo vcard qrcode
     * @param {DynamicQrControllerApiCreateVCardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVCard(
      requestParameters: DynamicQrControllerApiCreateVCardRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVCardResponse> {
      return localVarFp
        .createVCard(requestParameters.createVCardRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo website qrcode
     * @summary Tạo website qrcode
     * @param {DynamicQrControllerApiCreateWebsiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWebsite(
      requestParameters: DynamicQrControllerApiCreateWebsiteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseWebsiteResponse> {
      return localVarFp
        .createWebsite(requestParameters.createWebsiteRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật landing page qrcode
     * @summary Cập nhật landing page qrcode
     * @param {DynamicQrControllerApiUpdateLandingPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLandingPage(
      requestParameters: DynamicQrControllerApiUpdateLandingPageRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseLandingPageResponse> {
      return localVarFp
        .updateLandingPage(requestParameters.updateLandingPageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật menu qrcode
     * @summary Cập nhật menu qrcode
     * @param {DynamicQrControllerApiUpdateMenuRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMenu(
      requestParameters: DynamicQrControllerApiUpdateMenuRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseMenuResponse> {
      return localVarFp
        .updateMenu(requestParameters.updateMenuRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật vcard qrcode
     * @summary Cập nhật vcard qrcode
     * @param {DynamicQrControllerApiUpdateVCardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVCard(
      requestParameters: DynamicQrControllerApiUpdateVCardRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVCardResponse> {
      return localVarFp
        .updateVCard(requestParameters.updateVCardRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật website qrcode
     * @summary Cập nhật website qrcode
     * @param {DynamicQrControllerApiUpdateWebsiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWebsite(
      requestParameters: DynamicQrControllerApiUpdateWebsiteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseWebsiteResponse> {
      return localVarFp
        .updateWebsite(requestParameters.updateWebsiteRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createLandingPage operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiCreateLandingPageRequest
 */
export interface DynamicQrControllerApiCreateLandingPageRequest {
  /**
   *
   * @type {CreateLandingPageRequest}
   * @memberof DynamicQrControllerApiCreateLandingPage
   */
  readonly createLandingPageRequest: CreateLandingPageRequest;
}

/**
 * Request parameters for createMenu operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiCreateMenuRequest
 */
export interface DynamicQrControllerApiCreateMenuRequest {
  /**
   *
   * @type {CreateMenuRequest}
   * @memberof DynamicQrControllerApiCreateMenu
   */
  readonly createMenuRequest: CreateMenuRequest;
}

/**
 * Request parameters for createVCard operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiCreateVCardRequest
 */
export interface DynamicQrControllerApiCreateVCardRequest {
  /**
   *
   * @type {CreateVCardRequest}
   * @memberof DynamicQrControllerApiCreateVCard
   */
  readonly createVCardRequest: CreateVCardRequest;
}

/**
 * Request parameters for createWebsite operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiCreateWebsiteRequest
 */
export interface DynamicQrControllerApiCreateWebsiteRequest {
  /**
   *
   * @type {CreateWebsiteRequest}
   * @memberof DynamicQrControllerApiCreateWebsite
   */
  readonly createWebsiteRequest: CreateWebsiteRequest;
}

/**
 * Request parameters for updateLandingPage operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiUpdateLandingPageRequest
 */
export interface DynamicQrControllerApiUpdateLandingPageRequest {
  /**
   *
   * @type {UpdateLandingPageRequest}
   * @memberof DynamicQrControllerApiUpdateLandingPage
   */
  readonly updateLandingPageRequest: UpdateLandingPageRequest;
}

/**
 * Request parameters for updateMenu operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiUpdateMenuRequest
 */
export interface DynamicQrControllerApiUpdateMenuRequest {
  /**
   *
   * @type {UpdateMenuRequest}
   * @memberof DynamicQrControllerApiUpdateMenu
   */
  readonly updateMenuRequest: UpdateMenuRequest;
}

/**
 * Request parameters for updateVCard operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiUpdateVCardRequest
 */
export interface DynamicQrControllerApiUpdateVCardRequest {
  /**
   *
   * @type {UpdateVCardRequest}
   * @memberof DynamicQrControllerApiUpdateVCard
   */
  readonly updateVCardRequest: UpdateVCardRequest;
}

/**
 * Request parameters for updateWebsite operation in DynamicQrControllerApi.
 * @export
 * @interface DynamicQrControllerApiUpdateWebsiteRequest
 */
export interface DynamicQrControllerApiUpdateWebsiteRequest {
  /**
   *
   * @type {UpdateWebsiteRequest}
   * @memberof DynamicQrControllerApiUpdateWebsite
   */
  readonly updateWebsiteRequest: UpdateWebsiteRequest;
}

/**
 * DynamicQrControllerApi - object-oriented interface
 * @export
 * @class DynamicQrControllerApi
 * @extends {BaseAPI}
 */
export class DynamicQrControllerApi extends BaseAPI {
  /**
   * Tạo landing page qrcode
   * @summary Tạo landing page qrcode
   * @param {DynamicQrControllerApiCreateLandingPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public createLandingPage(
    requestParameters: DynamicQrControllerApiCreateLandingPageRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .createLandingPage(requestParameters.createLandingPageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo menu qrcode
   * @summary Tạo menu qrcode
   * @param {DynamicQrControllerApiCreateMenuRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public createMenu(
    requestParameters: DynamicQrControllerApiCreateMenuRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .createMenu(requestParameters.createMenuRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo vcard qrcode
   * @summary Tạo vcard qrcode
   * @param {DynamicQrControllerApiCreateVCardRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public createVCard(
    requestParameters: DynamicQrControllerApiCreateVCardRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .createVCard(requestParameters.createVCardRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo website qrcode
   * @summary Tạo website qrcode
   * @param {DynamicQrControllerApiCreateWebsiteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public createWebsite(
    requestParameters: DynamicQrControllerApiCreateWebsiteRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .createWebsite(requestParameters.createWebsiteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật landing page qrcode
   * @summary Cập nhật landing page qrcode
   * @param {DynamicQrControllerApiUpdateLandingPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public updateLandingPage(
    requestParameters: DynamicQrControllerApiUpdateLandingPageRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .updateLandingPage(requestParameters.updateLandingPageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật menu qrcode
   * @summary Cập nhật menu qrcode
   * @param {DynamicQrControllerApiUpdateMenuRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public updateMenu(
    requestParameters: DynamicQrControllerApiUpdateMenuRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .updateMenu(requestParameters.updateMenuRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật vcard qrcode
   * @summary Cập nhật vcard qrcode
   * @param {DynamicQrControllerApiUpdateVCardRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public updateVCard(
    requestParameters: DynamicQrControllerApiUpdateVCardRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .updateVCard(requestParameters.updateVCardRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật website qrcode
   * @summary Cập nhật website qrcode
   * @param {DynamicQrControllerApiUpdateWebsiteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicQrControllerApi
   */
  public updateWebsite(
    requestParameters: DynamicQrControllerApiUpdateWebsiteRequest,
    options?: AxiosRequestConfig,
  ) {
    return DynamicQrControllerApiFp(this.configuration)
      .updateWebsite(requestParameters.updateWebsiteRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FolderControllerApi - axios parameter creator
 * @export
 */
export const FolderControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {CreateFolderRequest} createFolderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createFolderRequest: CreateFolderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFolderRequest' is not null or undefined
      assertParamExists("create", "createFolderRequest", createFolderRequest);
      const localVarPath = `/api/folder/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFolderRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("delete1", "id", id);
      const localVarPath = `/api/folder/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail3: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail3", "id", id);
      const localVarPath = `/api/folder/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách đối tượng qua filter
     * @summary Lấy danh sách đối tượng qua filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredList: async (
      name?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/folder/v1/getFilteredList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList2: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/folder/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách đối tượng được phân trang
     * @summary Lấy danh sách đối tượng được phân trang
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sortDir]
     * @param {string} [sortBy]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageList: async (
      page?: number,
      size?: number,
      sortDir?: string,
      sortBy?: string,
      name?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/folder/v1/getPageList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {string} id
     * @param {UpdateFolderRequest} updateFolderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      id: string,
      updateFolderRequest: UpdateFolderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("update", "id", id);
      // verify required parameter 'updateFolderRequest' is not null or undefined
      assertParamExists("update", "updateFolderRequest", updateFolderRequest);
      const localVarPath = `/api/folder/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFolderRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FolderControllerApi - functional programming interface
 * @export
 */
export const FolderControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FolderControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {CreateFolderRequest} createFolderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createFolderRequest: CreateFolderRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseFolderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createFolderRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delete1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail3(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseFolderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail3(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách đối tượng qua filter
     * @summary Lấy danh sách đối tượng qua filter
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilteredList(
      name?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseFolderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredList(
        name,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList2(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseFolderResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getList2(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách đối tượng được phân trang
     * @summary Lấy danh sách đối tượng được phân trang
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sortDir]
     * @param {string} [sortBy]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPageList(
      page?: number,
      size?: number,
      sortDir?: string,
      sortBy?: string,
      name?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseFolderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPageList(
        page,
        size,
        sortDir,
        sortBy,
        name,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {string} id
     * @param {UpdateFolderRequest} updateFolderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      id: string,
      updateFolderRequest: UpdateFolderRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseFolderResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        id,
        updateFolderRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FolderControllerApi - factory interface
 * @export
 */
export const FolderControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FolderControllerApiFp(configuration);
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {FolderControllerApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      requestParameters: FolderControllerApiCreateRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseFolderResponse> {
      return localVarFp
        .create(requestParameters.createFolderRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {FolderControllerApiDelete1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete1(
      requestParameters: FolderControllerApiDelete1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .delete1(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {FolderControllerApiGetDetail3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail3(
      requestParameters: FolderControllerApiGetDetail3Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseFolderResponse> {
      return localVarFp
        .getDetail3(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách đối tượng qua filter
     * @summary Lấy danh sách đối tượng qua filter
     * @param {FolderControllerApiGetFilteredListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredList(
      requestParameters: FolderControllerApiGetFilteredListRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponseFolderResponse> {
      return localVarFp
        .getFilteredList(requestParameters.name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList2(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponseFolderResponse> {
      return localVarFp
        .getList2(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách đối tượng được phân trang
     * @summary Lấy danh sách đối tượng được phân trang
     * @param {FolderControllerApiGetPageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPageList(
      requestParameters: FolderControllerApiGetPageListRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponseFolderResponse> {
      return localVarFp
        .getPageList(
          requestParameters.page,
          requestParameters.size,
          requestParameters.sortDir,
          requestParameters.sortBy,
          requestParameters.name,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {FolderControllerApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      requestParameters: FolderControllerApiUpdateRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseFolderResponse> {
      return localVarFp
        .update(
          requestParameters.id,
          requestParameters.updateFolderRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for create operation in FolderControllerApi.
 * @export
 * @interface FolderControllerApiCreateRequest
 */
export interface FolderControllerApiCreateRequest {
  /**
   *
   * @type {CreateFolderRequest}
   * @memberof FolderControllerApiCreate
   */
  readonly createFolderRequest: CreateFolderRequest;
}

/**
 * Request parameters for delete1 operation in FolderControllerApi.
 * @export
 * @interface FolderControllerApiDelete1Request
 */
export interface FolderControllerApiDelete1Request {
  /**
   *
   * @type {string}
   * @memberof FolderControllerApiDelete1
   */
  readonly id: string;
}

/**
 * Request parameters for getDetail3 operation in FolderControllerApi.
 * @export
 * @interface FolderControllerApiGetDetail3Request
 */
export interface FolderControllerApiGetDetail3Request {
  /**
   *
   * @type {string}
   * @memberof FolderControllerApiGetDetail3
   */
  readonly id: string;
}

/**
 * Request parameters for getFilteredList operation in FolderControllerApi.
 * @export
 * @interface FolderControllerApiGetFilteredListRequest
 */
export interface FolderControllerApiGetFilteredListRequest {
  /**
   *
   * @type {string}
   * @memberof FolderControllerApiGetFilteredList
   */
  readonly name?: string;
}

/**
 * Request parameters for getPageList operation in FolderControllerApi.
 * @export
 * @interface FolderControllerApiGetPageListRequest
 */
export interface FolderControllerApiGetPageListRequest {
  /**
   *
   * @type {number}
   * @memberof FolderControllerApiGetPageList
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof FolderControllerApiGetPageList
   */
  readonly size?: number;

  /**
   *
   * @type {string}
   * @memberof FolderControllerApiGetPageList
   */
  readonly sortDir?: string;

  /**
   *
   * @type {string}
   * @memberof FolderControllerApiGetPageList
   */
  readonly sortBy?: string;

  /**
   *
   * @type {string}
   * @memberof FolderControllerApiGetPageList
   */
  readonly name?: string;
}

/**
 * Request parameters for update operation in FolderControllerApi.
 * @export
 * @interface FolderControllerApiUpdateRequest
 */
export interface FolderControllerApiUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof FolderControllerApiUpdate
   */
  readonly id: string;

  /**
   *
   * @type {UpdateFolderRequest}
   * @memberof FolderControllerApiUpdate
   */
  readonly updateFolderRequest: UpdateFolderRequest;
}

/**
 * FolderControllerApi - object-oriented interface
 * @export
 * @class FolderControllerApi
 * @extends {BaseAPI}
 */
export class FolderControllerApi extends BaseAPI {
  /**
   * Tạo đối tượng
   * @summary Tạo đối tượng
   * @param {FolderControllerApiCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public create(
    requestParameters: FolderControllerApiCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return FolderControllerApiFp(this.configuration)
      .create(requestParameters.createFolderRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xoá đối tượng
   * @summary Xoá đối tượng
   * @param {FolderControllerApiDelete1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public delete1(
    requestParameters: FolderControllerApiDelete1Request,
    options?: AxiosRequestConfig,
  ) {
    return FolderControllerApiFp(this.configuration)
      .delete1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết đối tượng
   * @summary Lấy chi tiết đối tượng
   * @param {FolderControllerApiGetDetail3Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getDetail3(
    requestParameters: FolderControllerApiGetDetail3Request,
    options?: AxiosRequestConfig,
  ) {
    return FolderControllerApiFp(this.configuration)
      .getDetail3(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách đối tượng qua filter
   * @summary Lấy danh sách đối tượng qua filter
   * @param {FolderControllerApiGetFilteredListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getFilteredList(
    requestParameters: FolderControllerApiGetFilteredListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return FolderControllerApiFp(this.configuration)
      .getFilteredList(requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách đối tượng
   * @summary Lấy danh sách đối tượng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getList2(options?: AxiosRequestConfig) {
    return FolderControllerApiFp(this.configuration)
      .getList2(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách đối tượng được phân trang
   * @summary Lấy danh sách đối tượng được phân trang
   * @param {FolderControllerApiGetPageListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public getPageList(
    requestParameters: FolderControllerApiGetPageListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return FolderControllerApiFp(this.configuration)
      .getPageList(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sortDir,
        requestParameters.sortBy,
        requestParameters.name,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật đối tượng
   * @summary Cập nhật đối tượng
   * @param {FolderControllerApiUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FolderControllerApi
   */
  public update(
    requestParameters: FolderControllerApiUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return FolderControllerApiFp(this.configuration)
      .update(
        requestParameters.id,
        requestParameters.updateFolderRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MediaControllerApi - axios parameter creator
 * @export
 */
export const MediaControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Upload ảnh
     * @summary Upload ảnh
     * @param {string} path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preview: async (
      path: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'path' is not null or undefined
      assertParamExists("preview", "path", path);
      const localVarPath = `/api/media/v1/preview/{path}`.replace(
        `{${"path"}}`,
        encodeURIComponent(String(path)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upload ảnh
     * @summary Upload ảnh
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImage: async (
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("uploadImage", "file", file);
      const localVarPath = `/api/media/v1/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MediaControllerApi - functional programming interface
 * @export
 */
export const MediaControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MediaControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Upload ảnh
     * @summary Upload ảnh
     * @param {string} path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async preview(
      path: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.preview(
        path,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Upload ảnh
     * @summary Upload ảnh
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadImage(
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUploadImageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(
        file,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MediaControllerApi - factory interface
 * @export
 */
export const MediaControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MediaControllerApiFp(configuration);
  return {
    /**
     * Upload ảnh
     * @summary Upload ảnh
     * @param {MediaControllerApiPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preview(
      requestParameters: MediaControllerApiPreviewRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<File> {
      return localVarFp
        .preview(requestParameters.path, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Upload ảnh
     * @summary Upload ảnh
     * @param {MediaControllerApiUploadImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImage(
      requestParameters: MediaControllerApiUploadImageRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUploadImageResponse> {
      return localVarFp
        .uploadImage(requestParameters.file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for preview operation in MediaControllerApi.
 * @export
 * @interface MediaControllerApiPreviewRequest
 */
export interface MediaControllerApiPreviewRequest {
  /**
   *
   * @type {string}
   * @memberof MediaControllerApiPreview
   */
  readonly path: string;
}

/**
 * Request parameters for uploadImage operation in MediaControllerApi.
 * @export
 * @interface MediaControllerApiUploadImageRequest
 */
export interface MediaControllerApiUploadImageRequest {
  /**
   *
   * @type {File}
   * @memberof MediaControllerApiUploadImage
   */
  readonly file: File;
}

/**
 * MediaControllerApi - object-oriented interface
 * @export
 * @class MediaControllerApi
 * @extends {BaseAPI}
 */
export class MediaControllerApi extends BaseAPI {
  /**
   * Upload ảnh
   * @summary Upload ảnh
   * @param {MediaControllerApiPreviewRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaControllerApi
   */
  public preview(
    requestParameters: MediaControllerApiPreviewRequest,
    options?: AxiosRequestConfig,
  ) {
    return MediaControllerApiFp(this.configuration)
      .preview(requestParameters.path, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Upload ảnh
   * @summary Upload ảnh
   * @param {MediaControllerApiUploadImageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaControllerApi
   */
  public uploadImage(
    requestParameters: MediaControllerApiUploadImageRequest,
    options?: AxiosRequestConfig,
  ) {
    return MediaControllerApiFp(this.configuration)
      .uploadImage(requestParameters.file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentControllerApi - axios parameter creator
 * @export
 */
export const PaymentControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Thanh toán bằng kiên long bank pay
     * @param {PaymentRequest} paymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    klbPay: async (
      paymentRequest: PaymentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'paymentRequest' is not null or undefined
      assertParamExists("klbPay", "paymentRequest", paymentRequest);
      const localVarPath = `/api/payment/v1/klbpay`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentControllerApi - functional programming interface
 * @export
 */
export const PaymentControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PaymentControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Thanh toán bằng kiên long bank pay
     * @param {PaymentRequest} paymentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async klbPay(
      paymentRequest: PaymentRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePaymentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.klbPay(
        paymentRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentControllerApi - factory interface
 * @export
 */
export const PaymentControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Thanh toán bằng kiên long bank pay
     * @param {PaymentControllerApiKlbPayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    klbPay(
      requestParameters: PaymentControllerApiKlbPayRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePaymentResponse> {
      return localVarFp
        .klbPay(requestParameters.paymentRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for klbPay operation in PaymentControllerApi.
 * @export
 * @interface PaymentControllerApiKlbPayRequest
 */
export interface PaymentControllerApiKlbPayRequest {
  /**
   *
   * @type {PaymentRequest}
   * @memberof PaymentControllerApiKlbPay
   */
  readonly paymentRequest: PaymentRequest;
}

/**
 * PaymentControllerApi - object-oriented interface
 * @export
 * @class PaymentControllerApi
 * @extends {BaseAPI}
 */
export class PaymentControllerApi extends BaseAPI {
  /**
   *
   * @summary Thanh toán bằng kiên long bank pay
   * @param {PaymentControllerApiKlbPayRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentControllerApi
   */
  public klbPay(
    requestParameters: PaymentControllerApiKlbPayRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentControllerApiFp(this.configuration)
      .klbPay(requestParameters.paymentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlanControllerApi - axios parameter creator
 * @export
 */
export const PlanControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {CreatePlanRequest} createPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create2: async (
      createPlanRequest: CreatePlanRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPlanRequest' is not null or undefined
      assertParamExists("create2", "createPlanRequest", createPlanRequest);
      const localVarPath = `/api/admin/plan/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPlanRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete3: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("delete3", "id", id);
      const localVarPath = `/api/admin/plan/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail2: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail2", "id", id);
      const localVarPath = `/api/plan/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail5: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail5", "id", id);
      const localVarPath = `/api/admin/plan/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList1: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/plan/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList4: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/plan/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {string} id
     * @param {UpdatePlanRequest} updatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update2: async (
      id: string,
      updatePlanRequest: UpdatePlanRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("update2", "id", id);
      // verify required parameter 'updatePlanRequest' is not null or undefined
      assertParamExists("update2", "updatePlanRequest", updatePlanRequest);
      const localVarPath = `/api/admin/plan/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePlanRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlanControllerApi - functional programming interface
 * @export
 */
export const PlanControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PlanControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {CreatePlanRequest} createPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create2(
      createPlanRequest: CreatePlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePlanResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create2(
        createPlanRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delete3(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delete3(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail2(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePlanResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail2(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail5(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePlanResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail5(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList1(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponsePlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getList1(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList4(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponsePlanResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getList4(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {string} id
     * @param {UpdatePlanRequest} updatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update2(
      id: string,
      updatePlanRequest: UpdatePlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePlanResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update2(
        id,
        updatePlanRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PlanControllerApi - factory interface
 * @export
 */
export const PlanControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PlanControllerApiFp(configuration);
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {PlanControllerApiCreate2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create2(
      requestParameters: PlanControllerApiCreate2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePlanResponse> {
      return localVarFp
        .create2(requestParameters.createPlanRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {PlanControllerApiDelete3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete3(
      requestParameters: PlanControllerApiDelete3Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .delete3(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {PlanControllerApiGetDetail2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail2(
      requestParameters: PlanControllerApiGetDetail2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePlanResponse> {
      return localVarFp
        .getDetail2(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {PlanControllerApiGetDetail5Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail5(
      requestParameters: PlanControllerApiGetDetail5Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePlanResponse> {
      return localVarFp
        .getDetail5(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList1(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponsePlanResponse> {
      return localVarFp
        .getList1(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList4(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponsePlanResponse> {
      return localVarFp
        .getList4(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {PlanControllerApiUpdate2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update2(
      requestParameters: PlanControllerApiUpdate2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePlanResponse> {
      return localVarFp
        .update2(
          requestParameters.id,
          requestParameters.updatePlanRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for create2 operation in PlanControllerApi.
 * @export
 * @interface PlanControllerApiCreate2Request
 */
export interface PlanControllerApiCreate2Request {
  /**
   *
   * @type {CreatePlanRequest}
   * @memberof PlanControllerApiCreate2
   */
  readonly createPlanRequest: CreatePlanRequest;
}

/**
 * Request parameters for delete3 operation in PlanControllerApi.
 * @export
 * @interface PlanControllerApiDelete3Request
 */
export interface PlanControllerApiDelete3Request {
  /**
   *
   * @type {string}
   * @memberof PlanControllerApiDelete3
   */
  readonly id: string;
}

/**
 * Request parameters for getDetail2 operation in PlanControllerApi.
 * @export
 * @interface PlanControllerApiGetDetail2Request
 */
export interface PlanControllerApiGetDetail2Request {
  /**
   *
   * @type {string}
   * @memberof PlanControllerApiGetDetail2
   */
  readonly id: string;
}

/**
 * Request parameters for getDetail5 operation in PlanControllerApi.
 * @export
 * @interface PlanControllerApiGetDetail5Request
 */
export interface PlanControllerApiGetDetail5Request {
  /**
   *
   * @type {string}
   * @memberof PlanControllerApiGetDetail5
   */
  readonly id: string;
}

/**
 * Request parameters for update2 operation in PlanControllerApi.
 * @export
 * @interface PlanControllerApiUpdate2Request
 */
export interface PlanControllerApiUpdate2Request {
  /**
   *
   * @type {string}
   * @memberof PlanControllerApiUpdate2
   */
  readonly id: string;

  /**
   *
   * @type {UpdatePlanRequest}
   * @memberof PlanControllerApiUpdate2
   */
  readonly updatePlanRequest: UpdatePlanRequest;
}

/**
 * PlanControllerApi - object-oriented interface
 * @export
 * @class PlanControllerApi
 * @extends {BaseAPI}
 */
export class PlanControllerApi extends BaseAPI {
  /**
   * Tạo đối tượng
   * @summary Tạo đối tượng
   * @param {PlanControllerApiCreate2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanControllerApi
   */
  public create2(
    requestParameters: PlanControllerApiCreate2Request,
    options?: AxiosRequestConfig,
  ) {
    return PlanControllerApiFp(this.configuration)
      .create2(requestParameters.createPlanRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xoá đối tượng
   * @summary Xoá đối tượng
   * @param {PlanControllerApiDelete3Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanControllerApi
   */
  public delete3(
    requestParameters: PlanControllerApiDelete3Request,
    options?: AxiosRequestConfig,
  ) {
    return PlanControllerApiFp(this.configuration)
      .delete3(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết đối tượng
   * @summary Lấy chi tiết đối tượng
   * @param {PlanControllerApiGetDetail2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanControllerApi
   */
  public getDetail2(
    requestParameters: PlanControllerApiGetDetail2Request,
    options?: AxiosRequestConfig,
  ) {
    return PlanControllerApiFp(this.configuration)
      .getDetail2(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết đối tượng
   * @summary Lấy chi tiết đối tượng
   * @param {PlanControllerApiGetDetail5Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanControllerApi
   */
  public getDetail5(
    requestParameters: PlanControllerApiGetDetail5Request,
    options?: AxiosRequestConfig,
  ) {
    return PlanControllerApiFp(this.configuration)
      .getDetail5(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách đối tượng
   * @summary Lấy danh sách đối tượng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanControllerApi
   */
  public getList1(options?: AxiosRequestConfig) {
    return PlanControllerApiFp(this.configuration)
      .getList1(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách đối tượng
   * @summary Lấy danh sách đối tượng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanControllerApi
   */
  public getList4(options?: AxiosRequestConfig) {
    return PlanControllerApiFp(this.configuration)
      .getList4(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật đối tượng
   * @summary Cập nhật đối tượng
   * @param {PlanControllerApiUpdate2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanControllerApi
   */
  public update2(
    requestParameters: PlanControllerApiUpdate2Request,
    options?: AxiosRequestConfig,
  ) {
    return PlanControllerApiFp(this.configuration)
      .update2(
        requestParameters.id,
        requestParameters.updatePlanRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PublicLinkQrControllerApi - axios parameter creator
 * @export
 */
export const PublicLinkQrControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get QrCode data bằng id
     * @summary Get QrCode data bằng id
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrInfoByCode: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getQrInfoByCode", "code", code);
      const localVarPath = `/api/qr/public/v1/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get VCard file
     * @summary Get VCard
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVcardFile: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("getVcardFile", "code", code);
      const localVarPath = `/api/qr/public/v1/vcf/{code}`.replace(
        `{${"code"}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PublicLinkQrControllerApi - functional programming interface
 * @export
 */
export const PublicLinkQrControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    PublicLinkQrControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Get QrCode data bằng id
     * @summary Get QrCode data bằng id
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQrInfoByCode(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBasePublicQrCodeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQrInfoByCode(
        code,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get VCard file
     * @summary Get VCard
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVcardFile(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVcardFile(
        code,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PublicLinkQrControllerApi - factory interface
 * @export
 */
export const PublicLinkQrControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PublicLinkQrControllerApiFp(configuration);
  return {
    /**
     * Get QrCode data bằng id
     * @summary Get QrCode data bằng id
     * @param {PublicLinkQrControllerApiGetQrInfoByCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQrInfoByCode(
      requestParameters: PublicLinkQrControllerApiGetQrInfoByCodeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBasePublicQrCodeResponse> {
      return localVarFp
        .getQrInfoByCode(requestParameters.code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get VCard file
     * @summary Get VCard
     * @param {PublicLinkQrControllerApiGetVcardFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVcardFile(
      requestParameters: PublicLinkQrControllerApiGetVcardFileRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<File> {
      return localVarFp
        .getVcardFile(requestParameters.code, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getQrInfoByCode operation in PublicLinkQrControllerApi.
 * @export
 * @interface PublicLinkQrControllerApiGetQrInfoByCodeRequest
 */
export interface PublicLinkQrControllerApiGetQrInfoByCodeRequest {
  /**
   *
   * @type {string}
   * @memberof PublicLinkQrControllerApiGetQrInfoByCode
   */
  readonly code: string;
}

/**
 * Request parameters for getVcardFile operation in PublicLinkQrControllerApi.
 * @export
 * @interface PublicLinkQrControllerApiGetVcardFileRequest
 */
export interface PublicLinkQrControllerApiGetVcardFileRequest {
  /**
   *
   * @type {string}
   * @memberof PublicLinkQrControllerApiGetVcardFile
   */
  readonly code: string;
}

/**
 * PublicLinkQrControllerApi - object-oriented interface
 * @export
 * @class PublicLinkQrControllerApi
 * @extends {BaseAPI}
 */
export class PublicLinkQrControllerApi extends BaseAPI {
  /**
   * Get QrCode data bằng id
   * @summary Get QrCode data bằng id
   * @param {PublicLinkQrControllerApiGetQrInfoByCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicLinkQrControllerApi
   */
  public getQrInfoByCode(
    requestParameters: PublicLinkQrControllerApiGetQrInfoByCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return PublicLinkQrControllerApiFp(this.configuration)
      .getQrInfoByCode(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get VCard file
   * @summary Get VCard
   * @param {PublicLinkQrControllerApiGetVcardFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicLinkQrControllerApi
   */
  public getVcardFile(
    requestParameters: PublicLinkQrControllerApiGetVcardFileRequest,
    options?: AxiosRequestConfig,
  ) {
    return PublicLinkQrControllerApiFp(this.configuration)
      .getVcardFile(requestParameters.code, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * QrCodeControllerApi - axios parameter creator
 * @export
 */
export const QrCodeControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("_delete", "id", id);
      const localVarPath = `/api/qrCode/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xoá tất cả qrcode
     * @summary Xoá tất cả qrcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllQrCode: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/qrCode/v1/deleteAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail1: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail1", "id", id);
      const localVarPath = `/api/qrCode/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách qrcode
     * @summary Lấy danh sách qrcode
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sortBy]
     * @param {'ASC' | 'DESC'} [sortDir]
     * @param {'WEBSITE' | 'PDF' | 'LIST_OF_LINKS' | 'PICTURES' | 'VIDEO' | 'BUSINESS' | 'VCARD' | 'MENU' | 'APP' | 'MP3' | 'COUPON' | 'FEEDBACK' | 'PLAYLIST' | 'LANDING_PAGE' | 'EVENT' | 'STATIC_WEBSITE' | 'STATIC_TEXT' | 'STATIC_WIRELESS' | 'STATIC_VIETQR'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListQrCode: async (
      page?: number,
      size?: number,
      sortBy?: string,
      sortDir?: "ASC" | "DESC",
      type?:
        | "WEBSITE"
        | "PDF"
        | "LIST_OF_LINKS"
        | "PICTURES"
        | "VIDEO"
        | "BUSINESS"
        | "VCARD"
        | "MENU"
        | "APP"
        | "MP3"
        | "COUPON"
        | "FEEDBACK"
        | "PLAYLIST"
        | "LANDING_PAGE"
        | "EVENT"
        | "STATIC_WEBSITE"
        | "STATIC_TEXT"
        | "STATIC_WIRELESS"
        | "STATIC_VIETQR",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/qrCode/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter["sortBy"] = sortBy;
      }

      if (sortDir !== undefined) {
        localVarQueryParameter["sortDir"] = sortDir;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QrCodeControllerApi - functional programming interface
 * @export
 */
export const QrCodeControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    QrCodeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xoá tất cả qrcode
     * @summary Xoá tất cả qrcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAllQrCode(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAllQrCode(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail1(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseQrCodeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail1(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách qrcode
     * @summary Lấy danh sách qrcode
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sortBy]
     * @param {'ASC' | 'DESC'} [sortDir]
     * @param {'WEBSITE' | 'PDF' | 'LIST_OF_LINKS' | 'PICTURES' | 'VIDEO' | 'BUSINESS' | 'VCARD' | 'MENU' | 'APP' | 'MP3' | 'COUPON' | 'FEEDBACK' | 'PLAYLIST' | 'LANDING_PAGE' | 'EVENT' | 'STATIC_WEBSITE' | 'STATIC_TEXT' | 'STATIC_WIRELESS' | 'STATIC_VIETQR'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListQrCode(
      page?: number,
      size?: number,
      sortBy?: string,
      sortDir?: "ASC" | "DESC",
      type?:
        | "WEBSITE"
        | "PDF"
        | "LIST_OF_LINKS"
        | "PICTURES"
        | "VIDEO"
        | "BUSINESS"
        | "VCARD"
        | "MENU"
        | "APP"
        | "MP3"
        | "COUPON"
        | "FEEDBACK"
        | "PLAYLIST"
        | "LANDING_PAGE"
        | "EVENT"
        | "STATIC_WEBSITE"
        | "STATIC_TEXT"
        | "STATIC_WIRELESS"
        | "STATIC_VIETQR",
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListQrCodeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListQrCode(
        page,
        size,
        sortBy,
        sortDir,
        type,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * QrCodeControllerApi - factory interface
 * @export
 */
export const QrCodeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QrCodeControllerApiFp(configuration);
  return {
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {QrCodeControllerApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(
      requestParameters: QrCodeControllerApiDeleteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        ._delete(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Xoá tất cả qrcode
     * @summary Xoá tất cả qrcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllQrCode(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .deleteAllQrCode(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {QrCodeControllerApiGetDetail1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail1(
      requestParameters: QrCodeControllerApiGetDetail1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseQrCodeResponse> {
      return localVarFp
        .getDetail1(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách qrcode
     * @summary Lấy danh sách qrcode
     * @param {QrCodeControllerApiGetListQrCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListQrCode(
      requestParameters: QrCodeControllerApiGetListQrCodeRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListQrCodeResponse> {
      return localVarFp
        .getListQrCode(
          requestParameters.page,
          requestParameters.size,
          requestParameters.sortBy,
          requestParameters.sortDir,
          requestParameters.type,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for _delete operation in QrCodeControllerApi.
 * @export
 * @interface QrCodeControllerApiDeleteRequest
 */
export interface QrCodeControllerApiDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof QrCodeControllerApiDelete
   */
  readonly id: string;
}

/**
 * Request parameters for getDetail1 operation in QrCodeControllerApi.
 * @export
 * @interface QrCodeControllerApiGetDetail1Request
 */
export interface QrCodeControllerApiGetDetail1Request {
  /**
   *
   * @type {string}
   * @memberof QrCodeControllerApiGetDetail1
   */
  readonly id: string;
}

/**
 * Request parameters for getListQrCode operation in QrCodeControllerApi.
 * @export
 * @interface QrCodeControllerApiGetListQrCodeRequest
 */
export interface QrCodeControllerApiGetListQrCodeRequest {
  /**
   *
   * @type {number}
   * @memberof QrCodeControllerApiGetListQrCode
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof QrCodeControllerApiGetListQrCode
   */
  readonly size?: number;

  /**
   *
   * @type {string}
   * @memberof QrCodeControllerApiGetListQrCode
   */
  readonly sortBy?: string;

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof QrCodeControllerApiGetListQrCode
   */
  readonly sortDir?: "ASC" | "DESC";

  /**
   *
   * @type {'WEBSITE' | 'PDF' | 'LIST_OF_LINKS' | 'PICTURES' | 'VIDEO' | 'BUSINESS' | 'VCARD' | 'MENU' | 'APP' | 'MP3' | 'COUPON' | 'FEEDBACK' | 'PLAYLIST' | 'LANDING_PAGE' | 'EVENT' | 'STATIC_WEBSITE' | 'STATIC_TEXT' | 'STATIC_WIRELESS' | 'STATIC_VIETQR'}
   * @memberof QrCodeControllerApiGetListQrCode
   */
  readonly type?:
    | "WEBSITE"
    | "PDF"
    | "LIST_OF_LINKS"
    | "PICTURES"
    | "VIDEO"
    | "BUSINESS"
    | "VCARD"
    | "MENU"
    | "APP"
    | "MP3"
    | "COUPON"
    | "FEEDBACK"
    | "PLAYLIST"
    | "LANDING_PAGE"
    | "EVENT"
    | "STATIC_WEBSITE"
    | "STATIC_TEXT"
    | "STATIC_WIRELESS"
    | "STATIC_VIETQR";
}

/**
 * QrCodeControllerApi - object-oriented interface
 * @export
 * @class QrCodeControllerApi
 * @extends {BaseAPI}
 */
export class QrCodeControllerApi extends BaseAPI {
  /**
   * Xoá đối tượng
   * @summary Xoá đối tượng
   * @param {QrCodeControllerApiDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QrCodeControllerApi
   */
  public _delete(
    requestParameters: QrCodeControllerApiDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return QrCodeControllerApiFp(this.configuration)
      ._delete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xoá tất cả qrcode
   * @summary Xoá tất cả qrcode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QrCodeControllerApi
   */
  public deleteAllQrCode(options?: AxiosRequestConfig) {
    return QrCodeControllerApiFp(this.configuration)
      .deleteAllQrCode(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết đối tượng
   * @summary Lấy chi tiết đối tượng
   * @param {QrCodeControllerApiGetDetail1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QrCodeControllerApi
   */
  public getDetail1(
    requestParameters: QrCodeControllerApiGetDetail1Request,
    options?: AxiosRequestConfig,
  ) {
    return QrCodeControllerApiFp(this.configuration)
      .getDetail1(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách qrcode
   * @summary Lấy danh sách qrcode
   * @param {QrCodeControllerApiGetListQrCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QrCodeControllerApi
   */
  public getListQrCode(
    requestParameters: QrCodeControllerApiGetListQrCodeRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return QrCodeControllerApiFp(this.configuration)
      .getListQrCode(
        requestParameters.page,
        requestParameters.size,
        requestParameters.sortBy,
        requestParameters.sortDir,
        requestParameters.type,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReportControllerApi - axios parameter creator
 * @export
 */
export const ReportControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Lấy data report cho dashboard
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboard: async (
      fromDate?: string,
      toDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/report/v1/dashboard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ReportControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Lấy data report cho dashboard
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDashboard(
      fromDate?: string,
      toDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseDashboardResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReportControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Lấy data report cho dashboard
     * @param {ReportControllerApiGetDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboard(
      requestParameters: ReportControllerApiGetDashboardRequest = {},
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseDashboardResponse> {
      return localVarFp
        .getDashboard(
          requestParameters.fromDate,
          requestParameters.toDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getDashboard operation in ReportControllerApi.
 * @export
 * @interface ReportControllerApiGetDashboardRequest
 */
export interface ReportControllerApiGetDashboardRequest {
  /**
   *
   * @type {string}
   * @memberof ReportControllerApiGetDashboard
   */
  readonly fromDate?: string;

  /**
   *
   * @type {string}
   * @memberof ReportControllerApiGetDashboard
   */
  readonly toDate?: string;
}

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
  /**
   *
   * @summary Lấy data report cho dashboard
   * @param {ReportControllerApiGetDashboardRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportControllerApi
   */
  public getDashboard(
    requestParameters: ReportControllerApiGetDashboardRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return ReportControllerApiFp(this.configuration)
      .getDashboard(
        requestParameters.fromDate,
        requestParameters.toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StaticQrControllerApi - axios parameter creator
 * @export
 */
export const StaticQrControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Tạo qrcode tĩnh cho website
     * @summary Tạo qrcode tĩnh cho website
     * @param {CreateStaticWebsiteRequest} createStaticWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStaticWebsite: async (
      createStaticWebsiteRequest: CreateStaticWebsiteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createStaticWebsiteRequest' is not null or undefined
      assertParamExists(
        "createStaticWebsite",
        "createStaticWebsiteRequest",
        createStaticWebsiteRequest,
      );
      const localVarPath = `/api/staticQr/v1/website`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createStaticWebsiteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo text qrcode
     * @summary Tạo text qrcode
     * @param {CreateTextQrRequest} createTextQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTextQr: async (
      createTextQrRequest: CreateTextQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTextQrRequest' is not null or undefined
      assertParamExists(
        "createTextQr",
        "createTextQrRequest",
        createTextQrRequest,
      );
      const localVarPath = `/api/staticQr/v1/text`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTextQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo vietqr qrcode
     * @summary Tạo vietqr qrcode
     * @param {CreateVietQrRequest} createVietQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVietQr: async (
      createVietQrRequest: CreateVietQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createVietQrRequest' is not null or undefined
      assertParamExists(
        "createVietQr",
        "createVietQrRequest",
        createVietQrRequest,
      );
      const localVarPath = `/api/staticQr/v1/vietqr`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createVietQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Tạo wifi qrcode
     * @summary Tạo wifi qrcode
     * @param {CreateWirelessRequest} createWirelessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWifi: async (
      createWirelessRequest: CreateWirelessRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWirelessRequest' is not null or undefined
      assertParamExists(
        "createWifi",
        "createWirelessRequest",
        createWirelessRequest,
      );
      const localVarPath = `/api/staticQr/v1/wifi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWirelessRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật qrcode tĩnh cho website
     * @summary Cập nhật qrcode tĩnh cho website
     * @param {UpdateStaticWebsiteRequest} updateStaticWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStaticWebsite: async (
      updateStaticWebsiteRequest: UpdateStaticWebsiteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateStaticWebsiteRequest' is not null or undefined
      assertParamExists(
        "updateStaticWebsite",
        "updateStaticWebsiteRequest",
        updateStaticWebsiteRequest,
      );
      const localVarPath = `/api/staticQr/v1/website`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateStaticWebsiteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật text qrcode
     * @summary Cập nhật text qrcode
     * @param {UpdateTextRequest} updateTextRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTextQr: async (
      updateTextRequest: UpdateTextRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateTextRequest' is not null or undefined
      assertParamExists("updateTextQr", "updateTextRequest", updateTextRequest);
      const localVarPath = `/api/staticQr/v1/text`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTextRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật vietqr qrcode
     * @summary Cập nhật vietqr qrcode
     * @param {UpdateVietQrRequest} updateVietQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVietQr: async (
      updateVietQrRequest: UpdateVietQrRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateVietQrRequest' is not null or undefined
      assertParamExists(
        "updateVietQr",
        "updateVietQrRequest",
        updateVietQrRequest,
      );
      const localVarPath = `/api/staticQr/v1/vietqr`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateVietQrRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật wifi qrcode
     * @summary Cập nhật wifi qrcode
     * @param {UpdateWirelessRequest} updateWirelessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWifi: async (
      updateWirelessRequest: UpdateWirelessRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateWirelessRequest' is not null or undefined
      assertParamExists(
        "updateWifi",
        "updateWirelessRequest",
        updateWirelessRequest,
      );
      const localVarPath = `/api/staticQr/v1/wifi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateWirelessRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaticQrControllerApi - functional programming interface
 * @export
 */
export const StaticQrControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    StaticQrControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Tạo qrcode tĩnh cho website
     * @summary Tạo qrcode tĩnh cho website
     * @param {CreateStaticWebsiteRequest} createStaticWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStaticWebsite(
      createStaticWebsiteRequest: CreateStaticWebsiteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticWebsiteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createStaticWebsite(
          createStaticWebsiteRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo text qrcode
     * @summary Tạo text qrcode
     * @param {CreateTextQrRequest} createTextQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTextQr(
      createTextQrRequest: CreateTextQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTextQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTextQr(
        createTextQrRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo vietqr qrcode
     * @summary Tạo vietqr qrcode
     * @param {CreateVietQrRequest} createVietQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createVietQr(
      createVietQrRequest: CreateVietQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVietQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVietQr(
        createVietQrRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Tạo wifi qrcode
     * @summary Tạo wifi qrcode
     * @param {CreateWirelessRequest} createWirelessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWifi(
      createWirelessRequest: CreateWirelessRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseWirelessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWifi(
        createWirelessRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật qrcode tĩnh cho website
     * @summary Cập nhật qrcode tĩnh cho website
     * @param {UpdateStaticWebsiteRequest} updateStaticWebsiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStaticWebsite(
      updateStaticWebsiteRequest: UpdateStaticWebsiteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStaticWebsiteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateStaticWebsite(
          updateStaticWebsiteRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật text qrcode
     * @summary Cập nhật text qrcode
     * @param {UpdateTextRequest} updateTextRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTextQr(
      updateTextRequest: UpdateTextRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTextQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTextQr(
        updateTextRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật vietqr qrcode
     * @summary Cập nhật vietqr qrcode
     * @param {UpdateVietQrRequest} updateVietQrRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateVietQr(
      updateVietQrRequest: UpdateVietQrRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseVietQrResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateVietQr(
        updateVietQrRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật wifi qrcode
     * @summary Cập nhật wifi qrcode
     * @param {UpdateWirelessRequest} updateWirelessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWifi(
      updateWirelessRequest: UpdateWirelessRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseWirelessResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWifi(
        updateWirelessRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * StaticQrControllerApi - factory interface
 * @export
 */
export const StaticQrControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StaticQrControllerApiFp(configuration);
  return {
    /**
     * Tạo qrcode tĩnh cho website
     * @summary Tạo qrcode tĩnh cho website
     * @param {StaticQrControllerApiCreateStaticWebsiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStaticWebsite(
      requestParameters: StaticQrControllerApiCreateStaticWebsiteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStaticWebsiteResponse> {
      return localVarFp
        .createStaticWebsite(
          requestParameters.createStaticWebsiteRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo text qrcode
     * @summary Tạo text qrcode
     * @param {StaticQrControllerApiCreateTextQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTextQr(
      requestParameters: StaticQrControllerApiCreateTextQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTextQrResponse> {
      return localVarFp
        .createTextQr(requestParameters.createTextQrRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo vietqr qrcode
     * @summary Tạo vietqr qrcode
     * @param {StaticQrControllerApiCreateVietQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVietQr(
      requestParameters: StaticQrControllerApiCreateVietQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVietQrResponse> {
      return localVarFp
        .createVietQr(requestParameters.createVietQrRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Tạo wifi qrcode
     * @summary Tạo wifi qrcode
     * @param {StaticQrControllerApiCreateWifiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWifi(
      requestParameters: StaticQrControllerApiCreateWifiRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseWirelessResponse> {
      return localVarFp
        .createWifi(requestParameters.createWirelessRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật qrcode tĩnh cho website
     * @summary Cập nhật qrcode tĩnh cho website
     * @param {StaticQrControllerApiUpdateStaticWebsiteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStaticWebsite(
      requestParameters: StaticQrControllerApiUpdateStaticWebsiteRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStaticWebsiteResponse> {
      return localVarFp
        .updateStaticWebsite(
          requestParameters.updateStaticWebsiteRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật text qrcode
     * @summary Cập nhật text qrcode
     * @param {StaticQrControllerApiUpdateTextQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTextQr(
      requestParameters: StaticQrControllerApiUpdateTextQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTextQrResponse> {
      return localVarFp
        .updateTextQr(requestParameters.updateTextRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật vietqr qrcode
     * @summary Cập nhật vietqr qrcode
     * @param {StaticQrControllerApiUpdateVietQrRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVietQr(
      requestParameters: StaticQrControllerApiUpdateVietQrRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseVietQrResponse> {
      return localVarFp
        .updateVietQr(requestParameters.updateVietQrRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật wifi qrcode
     * @summary Cập nhật wifi qrcode
     * @param {StaticQrControllerApiUpdateWifiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWifi(
      requestParameters: StaticQrControllerApiUpdateWifiRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseWirelessResponse> {
      return localVarFp
        .updateWifi(requestParameters.updateWirelessRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createStaticWebsite operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiCreateStaticWebsiteRequest
 */
export interface StaticQrControllerApiCreateStaticWebsiteRequest {
  /**
   *
   * @type {CreateStaticWebsiteRequest}
   * @memberof StaticQrControllerApiCreateStaticWebsite
   */
  readonly createStaticWebsiteRequest: CreateStaticWebsiteRequest;
}

/**
 * Request parameters for createTextQr operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiCreateTextQrRequest
 */
export interface StaticQrControllerApiCreateTextQrRequest {
  /**
   *
   * @type {CreateTextQrRequest}
   * @memberof StaticQrControllerApiCreateTextQr
   */
  readonly createTextQrRequest: CreateTextQrRequest;
}

/**
 * Request parameters for createVietQr operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiCreateVietQrRequest
 */
export interface StaticQrControllerApiCreateVietQrRequest {
  /**
   *
   * @type {CreateVietQrRequest}
   * @memberof StaticQrControllerApiCreateVietQr
   */
  readonly createVietQrRequest: CreateVietQrRequest;
}

/**
 * Request parameters for createWifi operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiCreateWifiRequest
 */
export interface StaticQrControllerApiCreateWifiRequest {
  /**
   *
   * @type {CreateWirelessRequest}
   * @memberof StaticQrControllerApiCreateWifi
   */
  readonly createWirelessRequest: CreateWirelessRequest;
}

/**
 * Request parameters for updateStaticWebsite operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiUpdateStaticWebsiteRequest
 */
export interface StaticQrControllerApiUpdateStaticWebsiteRequest {
  /**
   *
   * @type {UpdateStaticWebsiteRequest}
   * @memberof StaticQrControllerApiUpdateStaticWebsite
   */
  readonly updateStaticWebsiteRequest: UpdateStaticWebsiteRequest;
}

/**
 * Request parameters for updateTextQr operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiUpdateTextQrRequest
 */
export interface StaticQrControllerApiUpdateTextQrRequest {
  /**
   *
   * @type {UpdateTextRequest}
   * @memberof StaticQrControllerApiUpdateTextQr
   */
  readonly updateTextRequest: UpdateTextRequest;
}

/**
 * Request parameters for updateVietQr operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiUpdateVietQrRequest
 */
export interface StaticQrControllerApiUpdateVietQrRequest {
  /**
   *
   * @type {UpdateVietQrRequest}
   * @memberof StaticQrControllerApiUpdateVietQr
   */
  readonly updateVietQrRequest: UpdateVietQrRequest;
}

/**
 * Request parameters for updateWifi operation in StaticQrControllerApi.
 * @export
 * @interface StaticQrControllerApiUpdateWifiRequest
 */
export interface StaticQrControllerApiUpdateWifiRequest {
  /**
   *
   * @type {UpdateWirelessRequest}
   * @memberof StaticQrControllerApiUpdateWifi
   */
  readonly updateWirelessRequest: UpdateWirelessRequest;
}

/**
 * StaticQrControllerApi - object-oriented interface
 * @export
 * @class StaticQrControllerApi
 * @extends {BaseAPI}
 */
export class StaticQrControllerApi extends BaseAPI {
  /**
   * Tạo qrcode tĩnh cho website
   * @summary Tạo qrcode tĩnh cho website
   * @param {StaticQrControllerApiCreateStaticWebsiteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public createStaticWebsite(
    requestParameters: StaticQrControllerApiCreateStaticWebsiteRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .createStaticWebsite(
        requestParameters.createStaticWebsiteRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo text qrcode
   * @summary Tạo text qrcode
   * @param {StaticQrControllerApiCreateTextQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public createTextQr(
    requestParameters: StaticQrControllerApiCreateTextQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .createTextQr(requestParameters.createTextQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo vietqr qrcode
   * @summary Tạo vietqr qrcode
   * @param {StaticQrControllerApiCreateVietQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public createVietQr(
    requestParameters: StaticQrControllerApiCreateVietQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .createVietQr(requestParameters.createVietQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Tạo wifi qrcode
   * @summary Tạo wifi qrcode
   * @param {StaticQrControllerApiCreateWifiRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public createWifi(
    requestParameters: StaticQrControllerApiCreateWifiRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .createWifi(requestParameters.createWirelessRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật qrcode tĩnh cho website
   * @summary Cập nhật qrcode tĩnh cho website
   * @param {StaticQrControllerApiUpdateStaticWebsiteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public updateStaticWebsite(
    requestParameters: StaticQrControllerApiUpdateStaticWebsiteRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .updateStaticWebsite(
        requestParameters.updateStaticWebsiteRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật text qrcode
   * @summary Cập nhật text qrcode
   * @param {StaticQrControllerApiUpdateTextQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public updateTextQr(
    requestParameters: StaticQrControllerApiUpdateTextQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .updateTextQr(requestParameters.updateTextRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật vietqr qrcode
   * @summary Cập nhật vietqr qrcode
   * @param {StaticQrControllerApiUpdateVietQrRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public updateVietQr(
    requestParameters: StaticQrControllerApiUpdateVietQrRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .updateVietQr(requestParameters.updateVietQrRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật wifi qrcode
   * @summary Cập nhật wifi qrcode
   * @param {StaticQrControllerApiUpdateWifiRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticQrControllerApi
   */
  public updateWifi(
    requestParameters: StaticQrControllerApiUpdateWifiRequest,
    options?: AxiosRequestConfig,
  ) {
    return StaticQrControllerApiFp(this.configuration)
      .updateWifi(requestParameters.updateWirelessRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TemplateControllerApi - axios parameter creator
 * @export
 */
export const TemplateControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {CreateTemplateRequest} createTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1: async (
      createTemplateRequest: CreateTemplateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTemplateRequest' is not null or undefined
      assertParamExists(
        "create1",
        "createTemplateRequest",
        createTemplateRequest,
      );
      const localVarPath = `/api/admin/template/v1/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTemplateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete2: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("delete2", "id", id);
      const localVarPath = `/api/admin/template/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail", "id", id);
      const localVarPath = `/api/template/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail4: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getDetail4", "id", id);
      const localVarPath = `/api/admin/template/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/template/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList3: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/admin/template/v1/getList`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {string} id
     * @param {UpdateTemplateRequest} updateTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1: async (
      id: string,
      updateTemplateRequest: UpdateTemplateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("update1", "id", id);
      // verify required parameter 'updateTemplateRequest' is not null or undefined
      assertParamExists(
        "update1",
        "updateTemplateRequest",
        updateTemplateRequest,
      );
      const localVarPath = `/api/admin/template/v1/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTemplateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TemplateControllerApi - functional programming interface
 * @export
 */
export const TemplateControllerApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    TemplateControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {CreateTemplateRequest} createTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create1(
      createTemplateRequest: CreateTemplateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create1(
        createTemplateRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async delete2(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.delete2(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDetail4(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDetail4(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseTemplateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList3(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseListResponseTemplateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getList3(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {string} id
     * @param {UpdateTemplateRequest} updateTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update1(
      id: string,
      updateTemplateRequest: UpdateTemplateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseTemplateResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update1(
        id,
        updateTemplateRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TemplateControllerApi - factory interface
 * @export
 */
export const TemplateControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TemplateControllerApiFp(configuration);
  return {
    /**
     * Tạo đối tượng
     * @summary Tạo đối tượng
     * @param {TemplateControllerApiCreate1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create1(
      requestParameters: TemplateControllerApiCreate1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTemplateResponse> {
      return localVarFp
        .create1(requestParameters.createTemplateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Xoá đối tượng
     * @summary Xoá đối tượng
     * @param {TemplateControllerApiDelete2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    delete2(
      requestParameters: TemplateControllerApiDelete2Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .delete2(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {TemplateControllerApiGetDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail(
      requestParameters: TemplateControllerApiGetDetailRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTemplateResponse> {
      return localVarFp
        .getDetail(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy chi tiết đối tượng
     * @summary Lấy chi tiết đối tượng
     * @param {TemplateControllerApiGetDetail4Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDetail4(
      requestParameters: TemplateControllerApiGetDetail4Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTemplateResponse> {
      return localVarFp
        .getDetail4(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponseTemplateResponse> {
      return localVarFp
        .getList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Lấy danh sách đối tượng
     * @summary Lấy danh sách đối tượng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList3(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseListResponseTemplateResponse> {
      return localVarFp
        .getList3(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật đối tượng
     * @summary Cập nhật đối tượng
     * @param {TemplateControllerApiUpdate1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update1(
      requestParameters: TemplateControllerApiUpdate1Request,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseTemplateResponse> {
      return localVarFp
        .update1(
          requestParameters.id,
          requestParameters.updateTemplateRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for create1 operation in TemplateControllerApi.
 * @export
 * @interface TemplateControllerApiCreate1Request
 */
export interface TemplateControllerApiCreate1Request {
  /**
   *
   * @type {CreateTemplateRequest}
   * @memberof TemplateControllerApiCreate1
   */
  readonly createTemplateRequest: CreateTemplateRequest;
}

/**
 * Request parameters for delete2 operation in TemplateControllerApi.
 * @export
 * @interface TemplateControllerApiDelete2Request
 */
export interface TemplateControllerApiDelete2Request {
  /**
   *
   * @type {string}
   * @memberof TemplateControllerApiDelete2
   */
  readonly id: string;
}

/**
 * Request parameters for getDetail operation in TemplateControllerApi.
 * @export
 * @interface TemplateControllerApiGetDetailRequest
 */
export interface TemplateControllerApiGetDetailRequest {
  /**
   *
   * @type {string}
   * @memberof TemplateControllerApiGetDetail
   */
  readonly id: string;
}

/**
 * Request parameters for getDetail4 operation in TemplateControllerApi.
 * @export
 * @interface TemplateControllerApiGetDetail4Request
 */
export interface TemplateControllerApiGetDetail4Request {
  /**
   *
   * @type {string}
   * @memberof TemplateControllerApiGetDetail4
   */
  readonly id: string;
}

/**
 * Request parameters for update1 operation in TemplateControllerApi.
 * @export
 * @interface TemplateControllerApiUpdate1Request
 */
export interface TemplateControllerApiUpdate1Request {
  /**
   *
   * @type {string}
   * @memberof TemplateControllerApiUpdate1
   */
  readonly id: string;

  /**
   *
   * @type {UpdateTemplateRequest}
   * @memberof TemplateControllerApiUpdate1
   */
  readonly updateTemplateRequest: UpdateTemplateRequest;
}

/**
 * TemplateControllerApi - object-oriented interface
 * @export
 * @class TemplateControllerApi
 * @extends {BaseAPI}
 */
export class TemplateControllerApi extends BaseAPI {
  /**
   * Tạo đối tượng
   * @summary Tạo đối tượng
   * @param {TemplateControllerApiCreate1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public create1(
    requestParameters: TemplateControllerApiCreate1Request,
    options?: AxiosRequestConfig,
  ) {
    return TemplateControllerApiFp(this.configuration)
      .create1(requestParameters.createTemplateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Xoá đối tượng
   * @summary Xoá đối tượng
   * @param {TemplateControllerApiDelete2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public delete2(
    requestParameters: TemplateControllerApiDelete2Request,
    options?: AxiosRequestConfig,
  ) {
    return TemplateControllerApiFp(this.configuration)
      .delete2(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết đối tượng
   * @summary Lấy chi tiết đối tượng
   * @param {TemplateControllerApiGetDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public getDetail(
    requestParameters: TemplateControllerApiGetDetailRequest,
    options?: AxiosRequestConfig,
  ) {
    return TemplateControllerApiFp(this.configuration)
      .getDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy chi tiết đối tượng
   * @summary Lấy chi tiết đối tượng
   * @param {TemplateControllerApiGetDetail4Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public getDetail4(
    requestParameters: TemplateControllerApiGetDetail4Request,
    options?: AxiosRequestConfig,
  ) {
    return TemplateControllerApiFp(this.configuration)
      .getDetail4(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách đối tượng
   * @summary Lấy danh sách đối tượng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public getList(options?: AxiosRequestConfig) {
    return TemplateControllerApiFp(this.configuration)
      .getList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Lấy danh sách đối tượng
   * @summary Lấy danh sách đối tượng
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public getList3(options?: AxiosRequestConfig) {
    return TemplateControllerApiFp(this.configuration)
      .getList3(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật đối tượng
   * @summary Cập nhật đối tượng
   * @param {TemplateControllerApiUpdate1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public update1(
    requestParameters: TemplateControllerApiUpdate1Request,
    options?: AxiosRequestConfig,
  ) {
    return TemplateControllerApiFp(this.configuration)
      .update1(
        requestParameters.id,
        requestParameters.updateTemplateRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Lấy thông tin của chính user
     * @summary Lấy thông tin user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserInfo: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/user/v1/getInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tạo tài khoản
     * @param {RegisterUserRequest} registerUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register: async (
      registerUserRequest: RegisterUserRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerUserRequest' is not null or undefined
      assertParamExists("register", "registerUserRequest", registerUserRequest);
      const localVarPath = `/api/user/v1/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerUserRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Khôi phục mật khẩu
     * @summary Khôi phục mật khẩu
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword: async (
      resetPasswordRequest: ResetPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetPasswordRequest' is not null or undefined
      assertParamExists(
        "resetPassword",
        "resetPasswordRequest",
        resetPasswordRequest,
      );
      const localVarPath = `/api/user/v1/resetPassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Khôi phục mật khẩu: đổi mật khẩu
     * @summary Khôi phục mật khẩu: đổi mật khẩu
     * @param {ResetPasswordSubmitRequest} resetPasswordSubmitRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitResetPassword: async (
      resetPasswordSubmitRequest: ResetPasswordSubmitRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetPasswordSubmitRequest' is not null or undefined
      assertParamExists(
        "submitResetPassword",
        "resetPasswordSubmitRequest",
        resetPasswordSubmitRequest,
      );
      const localVarPath = `/api/user/v1/resetPassword/submit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordSubmitRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật avatar
     * @summary Cập nhật avatar
     * @param {UpdateAvatarRequest} updateAvatarRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAvatar: async (
      updateAvatarRequest: UpdateAvatarRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAvatarRequest' is not null or undefined
      assertParamExists(
        "updateAvatar",
        "updateAvatarRequest",
        updateAvatarRequest,
      );
      const localVarPath = `/api/user/v1/updateAvatar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAvatarRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật billing data
     * @summary Cập nhật billing data
     * @param {UpdateBillDataRequest} updateBillDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBillData: async (
      updateBillDataRequest: UpdateBillDataRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateBillDataRequest' is not null or undefined
      assertParamExists(
        "updateBillData",
        "updateBillDataRequest",
        updateBillDataRequest,
      );
      const localVarPath = `/api/user/v1/updateBillData`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBillDataRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Cập nhật thông tin user
     * @summary Cập nhật thông tin user
     * @param {UpdateUserInfoRequest} updateUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInfo: async (
      updateUserInfoRequest: UpdateUserInfoRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserInfoRequest' is not null or undefined
      assertParamExists(
        "updateInfo",
        "updateUserInfoRequest",
        updateUserInfoRequest,
      );
      const localVarPath = `/api/user/v1/updateInfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserInfoRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Đổi mật khẩu
     * @summary Đổi mật khẩu
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword: async (
      changePasswordRequest: ChangePasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'changePasswordRequest' is not null or undefined
      assertParamExists(
        "updatePassword",
        "changePasswordRequest",
        changePasswordRequest,
      );
      const localVarPath = `/api/user/v1/updatePassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserControllerApiAxiosParamCreator(configuration);
  return {
    /**
     * Lấy thông tin của chính user
     * @summary Lấy thông tin user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserInfo(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserInfo(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Tạo tài khoản
     * @param {RegisterUserRequest} registerUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register(
      registerUserRequest: RegisterUserRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register(
        registerUserRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Khôi phục mật khẩu
     * @summary Khôi phục mật khẩu
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPassword(
      resetPasswordRequest: ResetPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(
        resetPasswordRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Khôi phục mật khẩu: đổi mật khẩu
     * @summary Khôi phục mật khẩu: đổi mật khẩu
     * @param {ResetPasswordSubmitRequest} resetPasswordSubmitRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitResetPassword(
      resetPasswordSubmitRequest: ResetPasswordSubmitRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseAccessTokenResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.submitResetPassword(
          resetPasswordSubmitRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật avatar
     * @summary Cập nhật avatar
     * @param {UpdateAvatarRequest} updateAvatarRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAvatar(
      updateAvatarRequest: UpdateAvatarRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAvatar(
        updateAvatarRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật billing data
     * @summary Cập nhật billing data
     * @param {UpdateBillDataRequest} updateBillDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBillData(
      updateBillDataRequest: UpdateBillDataRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserDetailResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBillData(
        updateBillDataRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Cập nhật thông tin user
     * @summary Cập nhật thông tin user
     * @param {UpdateUserInfoRequest} updateUserInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInfo(
      updateUserInfoRequest: UpdateUserInfoRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInfo(
        updateUserInfoRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Đổi mật khẩu
     * @summary Đổi mật khẩu
     * @param {ChangePasswordRequest} changePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePassword(
      changePasswordRequest: ChangePasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(
        changePasswordRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserControllerApiFp(configuration);
  return {
    /**
     * Lấy thông tin của chính user
     * @summary Lấy thông tin user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserInfo(
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUserDetailResponse> {
      return localVarFp
        .getUserInfo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Tạo tài khoản
     * @param {UserControllerApiRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      requestParameters: UserControllerApiRegisterRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .register(requestParameters.registerUserRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Khôi phục mật khẩu
     * @summary Khôi phục mật khẩu
     * @param {UserControllerApiResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      requestParameters: UserControllerApiResetPasswordRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .resetPassword(requestParameters.resetPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Khôi phục mật khẩu: đổi mật khẩu
     * @summary Khôi phục mật khẩu: đổi mật khẩu
     * @param {UserControllerApiSubmitResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitResetPassword(
      requestParameters: UserControllerApiSubmitResetPasswordRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseAccessTokenResponse> {
      return localVarFp
        .submitResetPassword(
          requestParameters.resetPasswordSubmitRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật avatar
     * @summary Cập nhật avatar
     * @param {UserControllerApiUpdateAvatarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAvatar(
      requestParameters: UserControllerApiUpdateAvatarRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .updateAvatar(requestParameters.updateAvatarRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật billing data
     * @summary Cập nhật billing data
     * @param {UserControllerApiUpdateBillDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBillData(
      requestParameters: UserControllerApiUpdateBillDataRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUserDetailResponse> {
      return localVarFp
        .updateBillData(requestParameters.updateBillDataRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cập nhật thông tin user
     * @summary Cập nhật thông tin user
     * @param {UserControllerApiUpdateInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInfo(
      requestParameters: UserControllerApiUpdateInfoRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseUserResponse> {
      return localVarFp
        .updateInfo(requestParameters.updateUserInfoRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Đổi mật khẩu
     * @summary Đổi mật khẩu
     * @param {UserControllerApiUpdatePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword(
      requestParameters: UserControllerApiUpdatePasswordRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseStatusResponse> {
      return localVarFp
        .updatePassword(requestParameters.changePasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for register operation in UserControllerApi.
 * @export
 * @interface UserControllerApiRegisterRequest
 */
export interface UserControllerApiRegisterRequest {
  /**
   *
   * @type {RegisterUserRequest}
   * @memberof UserControllerApiRegister
   */
  readonly registerUserRequest: RegisterUserRequest;
}

/**
 * Request parameters for resetPassword operation in UserControllerApi.
 * @export
 * @interface UserControllerApiResetPasswordRequest
 */
export interface UserControllerApiResetPasswordRequest {
  /**
   *
   * @type {ResetPasswordRequest}
   * @memberof UserControllerApiResetPassword
   */
  readonly resetPasswordRequest: ResetPasswordRequest;
}

/**
 * Request parameters for submitResetPassword operation in UserControllerApi.
 * @export
 * @interface UserControllerApiSubmitResetPasswordRequest
 */
export interface UserControllerApiSubmitResetPasswordRequest {
  /**
   *
   * @type {ResetPasswordSubmitRequest}
   * @memberof UserControllerApiSubmitResetPassword
   */
  readonly resetPasswordSubmitRequest: ResetPasswordSubmitRequest;
}

/**
 * Request parameters for updateAvatar operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUpdateAvatarRequest
 */
export interface UserControllerApiUpdateAvatarRequest {
  /**
   *
   * @type {UpdateAvatarRequest}
   * @memberof UserControllerApiUpdateAvatar
   */
  readonly updateAvatarRequest: UpdateAvatarRequest;
}

/**
 * Request parameters for updateBillData operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUpdateBillDataRequest
 */
export interface UserControllerApiUpdateBillDataRequest {
  /**
   *
   * @type {UpdateBillDataRequest}
   * @memberof UserControllerApiUpdateBillData
   */
  readonly updateBillDataRequest: UpdateBillDataRequest;
}

/**
 * Request parameters for updateInfo operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUpdateInfoRequest
 */
export interface UserControllerApiUpdateInfoRequest {
  /**
   *
   * @type {UpdateUserInfoRequest}
   * @memberof UserControllerApiUpdateInfo
   */
  readonly updateUserInfoRequest: UpdateUserInfoRequest;
}

/**
 * Request parameters for updatePassword operation in UserControllerApi.
 * @export
 * @interface UserControllerApiUpdatePasswordRequest
 */
export interface UserControllerApiUpdatePasswordRequest {
  /**
   *
   * @type {ChangePasswordRequest}
   * @memberof UserControllerApiUpdatePassword
   */
  readonly changePasswordRequest: ChangePasswordRequest;
}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
  /**
   * Lấy thông tin của chính user
   * @summary Lấy thông tin user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUserInfo(options?: AxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUserInfo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Tạo tài khoản
   * @param {UserControllerApiRegisterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public register(
    requestParameters: UserControllerApiRegisterRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .register(requestParameters.registerUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Khôi phục mật khẩu
   * @summary Khôi phục mật khẩu
   * @param {UserControllerApiResetPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public resetPassword(
    requestParameters: UserControllerApiResetPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .resetPassword(requestParameters.resetPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Khôi phục mật khẩu: đổi mật khẩu
   * @summary Khôi phục mật khẩu: đổi mật khẩu
   * @param {UserControllerApiSubmitResetPasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public submitResetPassword(
    requestParameters: UserControllerApiSubmitResetPasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .submitResetPassword(
        requestParameters.resetPasswordSubmitRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật avatar
   * @summary Cập nhật avatar
   * @param {UserControllerApiUpdateAvatarRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updateAvatar(
    requestParameters: UserControllerApiUpdateAvatarRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .updateAvatar(requestParameters.updateAvatarRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật billing data
   * @summary Cập nhật billing data
   * @param {UserControllerApiUpdateBillDataRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updateBillData(
    requestParameters: UserControllerApiUpdateBillDataRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .updateBillData(requestParameters.updateBillDataRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cập nhật thông tin user
   * @summary Cập nhật thông tin user
   * @param {UserControllerApiUpdateInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updateInfo(
    requestParameters: UserControllerApiUpdateInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .updateInfo(requestParameters.updateUserInfoRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Đổi mật khẩu
   * @summary Đổi mật khẩu
   * @param {UserControllerApiUpdatePasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updatePassword(
    requestParameters: UserControllerApiUpdatePasswordRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserControllerApiFp(this.configuration)
      .updatePassword(requestParameters.changePasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WebhookControllerApi - axios parameter creator
 * @export
 */
export const WebhookControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Webhook klb pay
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    klbpay: async (
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xApiClient' is not null or undefined
      assertParamExists("klbpay", "xApiClient", xApiClient);
      // verify required parameter 'xApiValidate' is not null or undefined
      assertParamExists("klbpay", "xApiValidate", xApiValidate);
      // verify required parameter 'xApiTime' is not null or undefined
      assertParamExists("klbpay", "xApiTime", xApiTime);
      // verify required parameter 'encryptedBodyRequest' is not null or undefined
      assertParamExists("klbpay", "encryptedBodyRequest", encryptedBodyRequest);
      const localVarPath = `/api/webhook/v1/klbpay/notifyTransaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xApiClient != null) {
        localVarHeaderParameter["x-api-client"] = String(xApiClient);
      }

      if (xApiValidate != null) {
        localVarHeaderParameter["x-api-validate"] = String(xApiValidate);
      }

      if (xApiTime != null) {
        localVarHeaderParameter["x-api-time"] =
          typeof xApiTime === "string" ? xApiTime : JSON.stringify(xApiTime);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        encryptedBodyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WebhookControllerApi - functional programming interface
 * @export
 */
export const WebhookControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WebhookControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Webhook klb pay
     * @param {string} xApiClient
     * @param {string} xApiValidate
     * @param {number} xApiTime
     * @param {EncryptedBodyRequest} encryptedBodyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async klbpay(
      xApiClient: string,
      xApiValidate: string,
      xApiTime: number,
      encryptedBodyRequest: EncryptedBodyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseBaseString>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.klbpay(
        xApiClient,
        xApiValidate,
        xApiTime,
        encryptedBodyRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WebhookControllerApi - factory interface
 * @export
 */
export const WebhookControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WebhookControllerApiFp(configuration);
  return {
    /**
     *
     * @summary Webhook klb pay
     * @param {WebhookControllerApiKlbpayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    klbpay(
      requestParameters: WebhookControllerApiKlbpayRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBaseString> {
      return localVarFp
        .klbpay(
          requestParameters.xApiClient,
          requestParameters.xApiValidate,
          requestParameters.xApiTime,
          requestParameters.encryptedBodyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for klbpay operation in WebhookControllerApi.
 * @export
 * @interface WebhookControllerApiKlbpayRequest
 */
export interface WebhookControllerApiKlbpayRequest {
  /**
   *
   * @type {string}
   * @memberof WebhookControllerApiKlbpay
   */
  readonly xApiClient: string;

  /**
   *
   * @type {string}
   * @memberof WebhookControllerApiKlbpay
   */
  readonly xApiValidate: string;

  /**
   *
   * @type {number}
   * @memberof WebhookControllerApiKlbpay
   */
  readonly xApiTime: number;

  /**
   *
   * @type {EncryptedBodyRequest}
   * @memberof WebhookControllerApiKlbpay
   */
  readonly encryptedBodyRequest: EncryptedBodyRequest;
}

/**
 * WebhookControllerApi - object-oriented interface
 * @export
 * @class WebhookControllerApi
 * @extends {BaseAPI}
 */
export class WebhookControllerApi extends BaseAPI {
  /**
   *
   * @summary Webhook klb pay
   * @param {WebhookControllerApiKlbpayRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhookControllerApi
   */
  public klbpay(
    requestParameters: WebhookControllerApiKlbpayRequest,
    options?: AxiosRequestConfig,
  ) {
    return WebhookControllerApiFp(this.configuration)
      .klbpay(
        requestParameters.xApiClient,
        requestParameters.xApiValidate,
        requestParameters.xApiTime,
        requestParameters.encryptedBodyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
